<div class="publish-page">
    <div class="form">
        <mat-vertical-stepper>
            <mat-step label="Où ?">
                <ng-template matStepLabel>
                    <mat-icon>directions_car</mat-icon>
                    <span>Où ?</span>
                    <!-- <mat-icon class="tooltip" matTooltip="Destination final du voyage"
                        aria-label="Destination final du voyage" aria-hidden="false" aria-label="Help Icon"
                        fontIcon="help_outline" /> -->
                </ng-template>
                <form [formGroup]="itineraryAdvertForm" class="publish-advert-form">
                    <div class="input-form">
                        <div class="itinerary-organism">
                            <div formArrayName="itinerary">
                                <div *ngFor="let destination of itinerary.controls; let i = index">
                                    <div class="title-itinerary">
                                        <h3 *ngIf="i === 0" class="title-molecule" i18n>Départ</h3>
                                        <h3 *ngIf="i > 0 && i < itinerary.controls.length-1 " class="title-molecule"
                                            i18n>Étape {{i}}</h3>
                                        <h3 *ngIf="i === itinerary.controls.length-1" class="title-molecule" i18n>Arrivé
                                        </h3>
                                    </div>
                                    <div class="itinerary-molecule" [formGroupName]="i">
                                        <div class="input-container select-control country">
                                            <mat-form-field>
                                                <mat-label i18n>Pays</mat-label>
                                                <div>
                                                    <input matInput [matAutocomplete]="autoCountry"
                                                        formControlName="country" placeholder="Pick one">
                                                </div>
                                                <mat-autocomplete #autoCountry="matAutocomplete"
                                                    (optionSelected)="OnSelectedCountryDestination($event, i)"
                                                    [displayWith]="displayCountry">
                                                    <mat-option *ngFor="let country of countries" [value]="country">
                                                        {{ country.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="regions.length >= 2" class="input-container select-control region">
                                            <mat-form-field>
                                                <mat-label i18n>Région</mat-label>
                                                <div>
                                                    <input matInput [matAutocomplete]="autoRegion"
                                                        formControlName="region" placeholder="Pick one">
                                                </div>
                                                <mat-autocomplete #autoRegion="matAutocomplete"
                                                    (optionSelected)="OnSelectedRegionDestination($event, i)"
                                                    [displayWith]="displayRegion">
                                                    <mat-option *ngFor="let region of regions" [value]="region">
                                                        {{ region.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>

                                        <div *ngIf="cities.length >= 2" class="input-container select-control city">
                                            <mat-form-field>
                                                <mat-label i18n>Ville</mat-label>
                                                <div>
                                                    <input matInput [matAutocomplete]="autoCities"
                                                        formControlName="city" placeholder="Pick one">
                                                </div>
                                                <mat-autocomplete #autoCities="matAutocomplete"
                                                    (optionSelected)="OnSelectedCityDestination($event, i)"
                                                    [displayWith]="displayCity">
                                                    <mat-option *ngFor="let city of cities" [value]="city">
                                                        {{ city.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-step">
                        <button matStepperNext [disabled]="!itineraryAdvertForm.valid" i18n>Suivant</button>
                        <!--TODO mettre des validations pour empecher de cliquer-->
                    </div>
                </form>
            </mat-step>
            <mat-step label="Quand ?">
                <ng-template matStepLabel>
                    <mat-icon>date_range</mat-icon>
                    <span i18n>Quand ?</span>
                </ng-template>
                <form [formGroup]="dateTripAdvertForm" class="publish-advert-form">
                    <div class="input-form">
                        <div class="input-container date-trip-organism">
                            <mat-form-field>
                                <mat-label>Date de départ</mat-label>
                                <input matInput [matDatepicker]="startPicker" formControlName="start"
                                    [min]="minStartDate" (dateChange)="onSelectDate($event, true)">
                                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                <mat-datepicker #startPicker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field *ngIf="minEndDate">
                                <mat-label>Date de fin</mat-label>
                                <input matInput [matDatepicker]="endPicker" formControlName="end" [min]="minEndDate"
                                    (dateChange)="onSelectDate($event, false)">
                                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                <mat-datepicker #endPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="button-step">
                        <button matStepperPrevious i18n>Retour</button>
                        <button matStepperNext i18n>Suivant</button>
                    </div>
                </form>
            </mat-step>
            <mat-step label="Comment ?">
                <ng-template matStepLabel>
                    <mat-icon>how_to_reg</mat-icon>
                    <span i18n>Comment ?</span>
                </ng-template>
                <form [formGroup]="conditionAdvertForm" class="publish-advert-form">
                    <div class="step">
                        <div class="input-form">
                            <div class="input-container number-vehicle ">
                                <div class="is-tooltip">
                                    <span i18n>Nombre de personnes</span>
                                    <span class="tooltip"
                                        matTooltip="Nombre de personnes que vous souhaitez dans votre convoit"
                                        aria-label="Nombre de personnes que vous souhaitez dans votre convoit">
                                        <mat-icon aria-hidden="false" aria-label="Help Icon" fontIcon="help_outline" />
                                    </span>
                                </div>
                                <div class="counter">
                                    <button mat-button class="button-counter"
                                        (click)="Counter('numberVehicles', false)"><mat-icon>remove</mat-icon></button>
                                    <mat-form-field>
                                        <input matInput type="number" min="0" formControlName="numberVehicles"
                                            id="numberVehicle">
                                        <mat-hint i18n>min : 0 <=> max : 10</mat-hint>
                                    </mat-form-field>
                                    <button mat-button class="button-counter"
                                        (click)="Counter('numberVehicles', true)"><mat-icon>add</mat-icon></button>
                                </div>
                            </div>

                            <div class="primary-tags">
                                <div class="input-container primary-tags-organism">
                                    <div class="primary-tag-molecule" id="toll-container">
                                        <div class="label-primary-tag">
                                            <h4 i18n>Péages</h4>
                                            <fa-icon [icon]="faRoadBarrier" />
                                        </div>
                                        <mat-slide-toggle formControlName="toll"
                                            (toggleChange)="OnFirstTagChange('toll')" />
                                    </div>
                                    <div class="primary-tag-molecule" id="smoker-container">
                                        <div class="label-primary-tag">
                                            <h4 i18n>Fumeur</h4>
                                            <mat-icon aria-hidden="false" aria-label="Pets autorize or forbidden"
                                                fontIcon="smoking_rooms" />
                                        </div>
                                        <mat-slide-toggle formControlName="smoking"
                                            (toggleChange)="OnFirstTagChange('smoker')" />
                                    </div>

                                    <div class="primary-tag-molecule" id="animals-container">
                                        <div class="label-primary-tag">
                                            <h4 i18n>Animaux</h4>
                                            <mat-icon aria-hidden="false" aria-label="Pets authorized or forbidden"
                                                fontIcon="pets" />
                                        </div>
                                        <mat-slide-toggle formControlName="pets"
                                            (toggleChange)="OnFirstTagChange('pets')" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="button-step">
                            <button matStepperPrevious i18n>Retour</button>
                            <button matStepperNext [disabled]="!conditionAdvertForm.valid" i18n>Suivant</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step label="Et pour finir">
                <ng-template matStepLabel>
                    <mat-icon>how_to_reg</mat-icon>
                    <span>Et pour finir</span>
                </ng-template>
                <form [formGroup]="lastInfoAdvertForm" (submit)="PublishAdvert()" class="publish-advert-form">
                    <div class="input-form secondary-tags-organism">
                        <div class="secondary-tags-molecule">
                            <mat-form-field>
                                <mat-label i18n>Titre</mat-label>
                                <input matInput type="text" formControlName="title"
                                    placeholder="Ex : Sur la route du gruyère, ..." maxlength="100">
                                <!--TODO i18n placeholder-->
                            </mat-form-field>
                        </div>

                        <div class="secondary-tags-molecule">
                            <mat-form-field appearance="outline">
                                <mat-label>Description</mat-label>
                                <textarea matInput placeholder="Entrez votre texte ici"
                                    formControlName="description"></textarea>
                                <!-- <mat-hint>Vous pouvez entrer jusqu'à 500 caractères</mat-hint> -->
                            </mat-form-field>
                        </div>

                        <div class="secondary-tags-molecule">
                            <mat-form-field>
                                <input matInput type="text" [matAutocomplete]="autoCompleteTags"
                                    placeholder="Ajouter des tags à votre voyage" (input)="onInputChange($event)" />
                                    <mat-hint i18n>Cela permet aux voyageurs de filter selon leurs envie</mat-hint>

                                <mat-autocomplete #autoCompleteTags="matAutocomplete"
                                    (optionSelected)="onSelectedLastInfoTags($event)">
                                    <mat-option *ngFor="let tag of filteredLastInfoTags$ | async" [value]="tag"> {{tag}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <div class="secondary-tags-atom">
                                <div class="tag" *ngFor="let tagSelected of tagsSelected"
                                    (click)="removeSelectedTag(tagSelected)">
                                    {{tagSelected}}
                                </div>
                                <!--TODO ajouter le remove en hover ou mettre un petit rond avec une croix dans un coin-->
                            </div>

                            <!-- <mat-form-field>
                                <input matInput type="text" formControlName="title" placeholder="Recherche un tag ...">
                                TODO i18n placeholder -->
                            <!-- </mat-form-field> -->
                            <!-- <div class="tags-container">
                                <span *ngFor="let tag of tagsFilter" class="">
                                    <input class="input-checkbox" type="checkbox" name="{{tag}}" id="{{tag}}"
                                        (change)="OnSelectedTags(tag)" />
                                    <label class="tag" for="{{tag}}">{{tag}}</label>
                                </span>
                            </div> -->
                        </div>
                    </div>

                    <div class="button-step">
                        <button matStepperPrevious i18n>Retour</button>
                        <button type="submit" matStepperNext
                            [disabled]="!itineraryAdvertForm.valid && !dateTripAdvertForm.valid && !conditionAdvertForm.valid && !lastInfoAdvertForm.valid"
                            i18n>Publier</button>
                    </div>
                </form>
            </mat-step>
        </mat-vertical-stepper>
    </div>
    <div class="publish-advert-preview">
        <app-advert [advertToDisplay]="advertPreviewDisplay"></app-advert>
        PREVIEW -> TODO faire la preview détailé

    </div>


</div>