<nav class="nav-bar">
    <div class="left">
        <a class="logo-accueil" routerLink="/"><img class="img-logo-accueil" src="assets/LogoVoycamp.png"
            alt="LogoAccueil">Voycamp</a>
    <a routerLink="/dashboard">Annonces</a>
    <a class="publish-button" routerLink="/publication">Publier une annonce</a>
    </div>
    <div class="right">
        <a class="profil-button" routerLink="/profil"><img src="assets/user-icon.png" sizes="16px"/>Profil</a>
        <button class="disconnect" (click)="disconnect()">Déconnexion</button>
    </div>
</nav>