import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DashboardAdvertComponent } from './dashboard-advert/dashboard-advert.component'
import { PublishAdvertComponent } from './publish-advert/publish-advert.component'
import { FilterAdvertComponent } from './filter-advert/filter-advert.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxSliderModule } from 'ngx-slider-v2'
import { SharedModule } from 'src/app/modules/shared/shared.module'
import { SearchBarComponent } from './search-bar/search-bar.component'
import { MatStepperModule } from '@angular/material/stepper'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatNativeDateModule } from '@angular/material/core'
import { AdvertComponent } from './components/advert/advert.component'
import { MatButtonModule } from '@angular/material/button';
import { DetailComponent } from './detail/detail.component'

@NgModule({
  declarations: [
    DashboardAdvertComponent,
    PublishAdvertComponent,
    FilterAdvertComponent,
    SearchBarComponent,
    DetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSliderModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SharedModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatStepperModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatNativeDateModule,
    AdvertComponent,
  ],
})
export class AdvertModule { }
