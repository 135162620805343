import { Component, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import {
	faSearch,
	faRoadBarrier
} from '@fortawesome/free-solid-svg-icons'
import {
	AdvertDisplayed,
	CityAdvert,
	ConditionForm,
	CountryAdvert,
	DateTripForm,
	PublishAdvert,
	RegionAdvert,
	Destination,
	LastInfoAdvertForm,
	ItineraryForm,
} from 'src/models/advert'

import { tagsAdvert } from 'src/constants/tags'
import { AdvertService } from '../advert.service'
import { BehaviorSubject, debounceTime, distinctUntilChanged, merge } from 'rxjs'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { emptyAdvertDisplayed, maxVehicles, minVehicles } from 'src/constants/advert'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { jwtDecode, JwtPayload } from "jwt-decode";

@Component({
	selector: 'app-publish-advert',
	templateUrl: './publish-advert.component.html',
	styleUrls: ['./publish-advert.component.scss'],
})
export class PublishAdvertComponent implements OnInit {
	faSearch = faSearch
	faRoadBarrier = faRoadBarrier

	constructor(private advertService: AdvertService) { }


	//#region Local Variable

	// filteredCountriesDeparture$!: Observable<CountryAdvert[]>
	// filteredCountriesFinish$!: Observable<CountryAdvert[]>

	// filteredRegionsDeparture$!: Observable<RegionAdvert[]>
	// filteredRegionsFinish$!: Observable<RegionAdvert[]>

	// filteredCitiesDeparture$!: Observable<CityAdvert[]>
	// filteredCitiesFinish$!: Observable<CityAdvert[]>

	tags: string[] = tagsAdvert
	tagsSelected: string[] = []
	filteredLastInfoTags$ = new BehaviorSubject<string[]>(this.tags);

	regions: RegionAdvert[] = []
	cities: CityAdvert[] = []
	countries: CountryAdvert[] = []

	indexCountrySelected: number = -1
	indexRegionSelected: number = -1
	indexCitiesSelected: number = -1

	minEndDate: Date | null = null
	minStartDate: Date = new Date()
	//#endregion

	//#region Forms

	itineraryAdvertForm = new FormGroup<ItineraryForm>({
		itinerary: new FormArray([this.createDestinationForm(), this.createDestinationForm()])
	})

	dateTripAdvertForm = new FormGroup<DateTripForm>({
		start: new FormControl(),
		end: new FormControl(),
	})

	conditionAdvertForm = new FormGroup<ConditionForm>({
		toll: new FormControl(false, {
			nonNullable: true,
			validators: Validators.required,
		}),
		smoking: new FormControl(false, {
			nonNullable: true,
			validators: Validators.required,
		}),
		pets: new FormControl(false, {
			nonNullable: true,
			validators: Validators.required,
		}),
		numberVehicles: new FormControl(0, {
			nonNullable: true,
			validators: [Validators.min(minVehicles), Validators.max(maxVehicles), Validators.nullValidator]
		})
	})

	lastInfoAdvertForm = new FormGroup<LastInfoAdvertForm>({
		title: new FormControl('', {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(50),
			],
		}),
		tags: new FormControl<string[]>([], {
			nonNullable: true,
		}), //TODO Validator ENUM
		description: new FormControl('')
	})
	//#endregion

	advertPreviewDisplay: AdvertDisplayed = emptyAdvertDisplayed

	ngOnInit(): void {
		this.InitDestinationsChoices()
		this.InitObservable()
		this.InitValuePreviewAdvert()
	}

	GetDecodedAccessToken(token: string): any {
		try {
			return jwtDecode<JwtPayload>(token); // Returns with the JwtPayload type
		} catch (Error) {
			return null;
		}
	}

	InitValuePreviewAdvert() {
		if (localStorage.getItem('authToken') !== null) {
			const tokenDecoded = this.GetDecodedAccessToken(localStorage.getItem('authToken')!)
			this.advertPreviewDisplay.user.name = tokenDecoded.name
			this.advertPreviewDisplay.user.picture = tokenDecoded.picture
		}

		this.lastInfoAdvertForm.get('title')?.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged()
			)
			.subscribe(value => {
				this.advertPreviewDisplay.title = value
			})

		this.lastInfoAdvertForm.get('description')?.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged()
			)
			.subscribe(value => {
				if (value) this.advertPreviewDisplay.description = value
			})
	}
	//#region Init Observable
	InitObservable() {
		// if (this.itineraryAdvertForm.get('itinerary')) {
		// 	const itineraryArray = this.itineraryAdvertForm.get('itinerary') as FormArray;

		// 	// itineraryArray.controls.forEach((group, index) => {
		// 	// 	// Country
		// 	// 	const countryControl = group.get('country');
		// 	// 	if (countryControl) {
		// 	// 		this.filteredCountriesDeparture$ = countryControl.valueChanges.pipe(
		// 	// 			startWith(''),
		// 	// 			map(value => this._filterCountries(value))
		// 	// 		);
		// 	// 	}

		// 	// Region
		// 	// const regionControl = group.get('region');
		// 	// if (regionControl) {
		// 	// 	this.filteredRegionsDeparture$ = regionControl.valueChanges.pipe(
		// 	// 		startWith(''),
		// 	// 		map(value => this._filterRegions(value, 'departure'))
		// 	// 	);
		// 	// }

		// 	// 	// City
		// 	// 	const cityControl = group.get('city');
		// 	// 	if (cityControl) {
		// 	// 		this.filteredCitiesDeparture$ = cityControl.valueChanges.pipe(
		// 	// 			startWith(''),
		// 	// 			map(value => this._filterCities(value, 'departure'))
		// 	// 		);
		// 	// 	}
		// 	// });
		// }
	}

	onInputChange(value: Event): void {
		const target = value.target as HTMLInputElement | null;
		if (target) {
			const inputValue = target.value;
			this.filteredLastInfoTags$.next(this._filterLastInfoTags(inputValue));
		}
	}

	//#region FilterMatAutoComplete
	private _filterLastInfoTags(value: string): string[] {
		const filterValue = value.toLocaleLowerCase();
		return this.tags.filter(tag => tag.toLocaleLowerCase().includes(filterValue));
	}

	// private _filterCountries(value: string | CountryAdvert): CountryAdvert[] {
	// 	const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
	// 	return this.countries.filter(country => country.name.toLowerCase().includes(filterValue))
	// 		.map(country => ({ name: country.name, isoCode: country.isoCode }))
	// }

	// private _filterRegions(value: string | RegionAdvert | undefined, group: Itinerary): RegionAdvert[] {
	// 	let regionToFilter = group === 'departure' ? this.regionsDeparture : this.regionsFinish
	// 	if (!value) return regionToFilter

	// 	const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
	// 	return regionToFilter.filter(region => region.name.toLowerCase().includes(filterValue)).map(region => ({ name: region.name, isoCode: region.isoCode, countryCode: region.countryCode }))
	// }

	// private _filterCities(value: string | CityAdvert | undefined, group: Itinerary): CityAdvert[] {
	// 	let citiesToFilter = group === 'departure' ? this.citiesDeparture : this.citiesFinish
	// 	if (!value) return citiesToFilter

	// 	const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase()
	// 	return citiesToFilter.filter(city => city.name.toLowerCase().includes(filterValue))
	// }
	//#endregion
	//#endregion

	isEmpty(value: any) {
		return (value == null || (typeof value === 'string' && value.trim().length === 0))
	}

	//#region Itinerary
	displayCountry(country: CountryAdvert): string {
		return country.isoCode ? `${country.name}` : '';
	}

	displayRegion(region: RegionAdvert): string {
		if (!region) return ''
		return region.isoCode !== 'UND' ? `${region.name}` : 'Pas de préférence';
	}
	displayCity(city: CityAdvert): string {
		if (!city) return ''
		return city.countryCode !== 'UND' ? `${city.name}` : 'Pas de préférence';
	}

	InitDestinationsChoices() {
		this.countries = this.advertService.getCountries()
		// this.cities = [{ name: 'Pas de préférence', countryCode: 'UND', stateCode: 'UND' }] //TODO MAYBE REMOVE
	}


	createDestinationForm(): FormGroup {
		return new FormGroup({
			country: new FormControl({ name: '', isoCode: '' }, { nonNullable: true }),
			region: new FormControl<RegionAdvert | undefined>(undefined, { nonNullable: true }),
			city: new FormControl<CityAdvert | undefined>(undefined, { nonNullable: true }),
		});
	}

	get itinerary() {
		return (this.itineraryAdvertForm.get('itinerary') as FormArray);
	}
	//#endregion Itinerary 


	Counter(controlName: string, increment: boolean) {
		const control = this.conditionAdvertForm.get(controlName)
		if (!control) return

		const newValue = Math.min(maxVehicles, Math.max(minVehicles, (control.value ?? 0) + (increment ? 1 : -1)))
		control.setValue(newValue)
		this.advertPreviewDisplay.numberVehicles = newValue
	}

	SwitchStepThree(event: string) {
		console.log(event)

		const numberVehiclesControl = this.conditionAdvertForm.get('numberVehicles')
		const tollControl = this.conditionAdvertForm.get('toll')
		const smokingControl = this.conditionAdvertForm.get('smoking')
		const petsControl = this.conditionAdvertForm.get('pets')

		if (this.isEmpty(event)) {
			numberVehiclesControl?.enable()
			tollControl?.enable()
			smokingControl?.enable()
			petsControl?.enable()
		} else {
			numberVehiclesControl?.disable()
			tollControl?.disable()
			smokingControl?.disable()
			petsControl?.disable()
		}
	}

	CheckValue() {
	}

	onSelectDate(event: MatDatepickerInputEvent<Date>, isStartDate: boolean) {
		if (isStartDate) {
			this.minEndDate = event.value;
			this.advertPreviewDisplay.dateTrip.start = event.value?.toLocaleDateString('fr-FR')

			const endDate = this.dateTripAdvertForm.get('end')?.value
			if (endDate && this.minEndDate && endDate < this.minEndDate) {
				this.advertPreviewDisplay.dateTrip.end = undefined
				this.dateTripAdvertForm.get('end')?.setValue(undefined)
			}
		}
		else {
			this.advertPreviewDisplay.dateTrip.end = event.value?.toLocaleDateString('fr-FR')
		}
	}

	OnFirstTagChange(controlName: 'toll' | 'smoker' | 'pets') {
		this.advertPreviewDisplay[controlName] = !this.advertPreviewDisplay[controlName];
	}


	onTitleChange(value: string) {
		console.log('Titre en direct :', value)
		this.advertPreviewDisplay.title = value
	}


	//#region Filters


	onSelectedLastInfoTags(event: MatAutocompleteSelectedEvent) {
		const indexLastSelectedTag = this.tagsSelected.findIndex(el => el === event.option.value)
		if (indexLastSelectedTag === -1) {
			this.tagsSelected.push(event.option.value)
		}
		else {
			this.tagsSelected.splice(indexLastSelectedTag, 1)
		}
		this.lastInfoAdvertForm.patchValue({ tags: this.tagsSelected })
		this.advertPreviewDisplay.tags = this.tagsSelected
	}

	removeSelectedTag(tagToRemove: string) {
		const indexLastSelectedTag = this.tagsSelected.findIndex(el => el === tagToRemove)
		this.tagsSelected.splice(indexLastSelectedTag, 1)
	}
	//#endregion Filters

	//#region Select Destination
	OnSelectedCountryDestination(event: MatAutocompleteSelectedEvent, index: number) {
		this.indexCountrySelected = this.countries.findIndex(
			(element) => element.isoCode === event.option.value.isoCode
		)

		this.advertPreviewDisplay.itinerary[index].country = event.option.value

		const countrySelected = this.countries[this.indexCountrySelected]
		this.regions = this.advertService.getRegionOfCountry(countrySelected.isoCode)
	}

	OnSelectedRegionDestination(event: MatAutocompleteSelectedEvent, index: number) {
		this.indexRegionSelected = this.regions.findIndex(
			(element: any) => element.name === event.option.value.name
		)

		this.advertPreviewDisplay.itinerary[index].region = event.option.value

		this.cities = this.advertService.getCitiesOfState(
			this.countries[this.indexCountrySelected].isoCode,
			this.regions[this.indexRegionSelected].isoCode
		)

		if (this.cities.length < 0) this.cities = [{ stateCode: '', name: 'Aucune ville', countryCode: '' }]
	}

	// OnSelectedRegionDestination(event: any, group: Itinerary) {
	// 	let groupRegion = group === 'departure' ? this.regionsDeparture : this.regionsFinish

	// 	this.indexRegionSelected = groupRegion.findIndex(
	// 		(element: any) => element.name === event.option.value.name
	// 	)

	// 	if (group === 'departure') {
	// 		this.citiesDeparture = this.advertService.getCitiesOfState(
	// 			this.countries[this.indexCountrySelected].isoCode,
	// 			groupRegion[this.indexRegionSelected].isoCode
	// 		)
	// 		if (this.citiesDeparture.length < 0) this.citiesDeparture = [{ stateCode: '', name: 'Aucune ville', countryCode: '' }]
	// 	}
	// 	else if (group === 'finish') {
	// 		this.citiesFinish = this.advertService.getCitiesOfState(
	// 			this.countries[this.indexCountrySelected].isoCode,
	// 			groupRegion[this.indexRegionSelected].isoCode
	// 		)
	// 		if (this.citiesFinish.length < 0) this.citiesFinish = [{ stateCode: '', name: 'Aucune ville', countryCode: '' }]
	// 	}
	// }

	OnSelectedCityDestination(event: MatAutocompleteSelectedEvent, index: number) {
		this.indexCitiesSelected = this.cities.findIndex(
			(element: any) => element.name === event.option.value.name
		)
		this.advertPreviewDisplay.itinerary[index].city = event.option.value
	}

	// OnSelectedCityDestination(event: any, group: Itinerary) {
	// 	let groupCities = group === 'departure' ? this.citiesDeparture : this.citiesFinish

	// 	this.indexCitiesSelected = groupCities.findIndex(
	// 		(element: any) => element.name === event.option.value.name
	// 	)
	// }

	//#endregion 

	PublishAdvert() {
		if (
			this.itineraryAdvertForm.valid && this.dateTripAdvertForm.valid && this.conditionAdvertForm.valid && this.lastInfoAdvertForm.valid
		) {

			const dataAdvert: PublishAdvert = {
				title: this.lastInfoAdvertForm.value.title as string,
				description: this.lastInfoAdvertForm.value.description as string,
				itinerary: this.itineraryAdvertForm.value as Destination[],
				numberVehicles: this.conditionAdvertForm.value.numberVehicles as number,
				user: {
					name: 'userTemp',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: [],
					description: ''
				},
				tags: this.lastInfoAdvertForm.value.tags as string[],
				toll: this.conditionAdvertForm.value.toll as boolean,
				smoking: this.conditionAdvertForm.value.smoking as boolean,
				pets: this.conditionAdvertForm.value.pets as boolean,
				dateTrip: {
					start: this.dateTripAdvertForm.value.start
						? new Date(this.dateTripAdvertForm.value.start)
						: undefined,
					end: this.dateTripAdvertForm.value.end
						? new Date(this.dateTripAdvertForm.value.end)
						: undefined,
				},
			}

			this.advertService.addAdvert(dataAdvert)
		}
	}
}
