import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCalendarDays,
  faUserGroup,
  faRoadBarrier,
  faCheck,
  faXmark,
  faPaperPlane,
  faArrowRight,
  faPaw,
  faSmoking,
} from '@fortawesome/free-solid-svg-icons'
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { PublicService } from 'src/app/public/public.service';
import { AdvertDisplayed } from 'src/models/advert';
import { emptyAdvertDisplayed } from 'src/constants/advert';

@Component({
  selector: 'app-advert',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, SharedModule],
  templateUrl: './advert.component.html',
  styleUrl: './advert.component.scss'
})
export class AdvertComponent {

  faCalendarDays = faCalendarDays
  faUserGroup = faUserGroup
  faCheck = faCheck
  faXmark = faXmark
  faRoadBarrier = faRoadBarrier
  faPaw = faPaw
  faSmoking = faSmoking
  faPaperPlane = faPaperPlane
  faArrowRight = faArrowRight

  @Input({ required: true }) advertToDisplay: AdvertDisplayed = emptyAdvertDisplayed;

  constructor(private publicService: PublicService) { }

  //TODO  map last country in itinerary in object advertToDisplay with a property

  sendFirstContact() {
    if (localStorage.getItem('authToken') !== null) {
      //TODO do sending information...
      console.log("send infos")

    }
    else {
      this.publicService.openLogInDialog();
    }
  }
}
