<nav class="nav-bar">
    <div class="left">  
        <a class="logo-accueil" routerLink="/"><img class="img-logo-accueil" src="assets/LogoVoycamp.png"
                alt="LogoAccueil">Voycamp</a>
        <a routerLink="/dashboard">Annonces</a>
        <a class="publish-button" routerLink="/publication">Publier une annonce</a>
    </div>
    <div class="right">
        <button class="connection-button" (click)="this.openLogInDialog()">Connexion</button>
        <button class="register-button" (click)="this.openRegisterDialog()">Inscription</button>
    </div>
</nav>