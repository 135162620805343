<div class="login-component">
    <form [formGroup]="loginForm" (submit)="login()" class="login-form">
        <div class="login-container">
            <h1>Connexion</h1>
            <button class="close-button" (click)="closeLoginDialog()">X</button>

            <div class="inputs-login">
                <mat-form-field>
                    <mat-label i18n>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-error
                        *ngIf="loginForm.get('email')?.hasError('email') && !loginForm.get('email')?.hasError('required')"
                        i18n>
                        Merci de renseigner une adresse email valide
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('email')?.hasError('required')" i18n>
                        L'email est <strong>obligatoire</strong>
                    </mat-error>
                </mat-form-field>
                <div class="password-container">
                    <mat-form-field>
                        <mat-label i18n>Mot de passe</mat-label>
                        <input matInput type="password" formControlName="password">
                        <mat-hint>
                            <a routerLink="/ForgetPassword" (click)="closeLoginDialog()" class="forget-password"
                                i18n>Mot de
                                passe oublié</a>
                        </mat-hint>

                        <!-- <mat-error *ngIf="loginForm.get('password')?.hasError('required')" i18n>
                            Le mot de passe est <strong>obligatoire</strong>
                        </mat-error> //Reason to remove : because hide forgetPassword from mat-hint-->
                    </mat-form-field>
                </div>
            </div>
            <button type="submit" class="button-style" [disabled]="!loginForm">Connexion</button>
            <p class="center-content" i18n>Ou</p>
            <button class="button-style" (click)="goToRegisterForm()" i18n>Inscription</button>
        </div>
    </form>
    <div *ngIf="errorLogIn" i18n>Problème lors de la connexion</div>
</div>