<div class="section first-section-header">
    <div class="search-section">
        <div class="search-container">
            <p>Où souhaitez-vous aller ?</p>
            <div class="search-bar">
                <div class="search-entries">
                    <div class="destination">
                        <fa-icon class="" [icon]="faLocationDot"></fa-icon>
                        <input type="text" class="search-input" placeholder="Destination">
                    </div>
                    <div class="dates">
                        <fa-icon [icon]="faCalendarDays"></fa-icon>
                        <input placeholder="Départ" class="search-input" type="text" onfocusin="(this.type='date')"
                            onfocusout="(this.type='text')" id="date">
                        <span class="search-input">|</span>
                        <input placeholder="Retour" class="search-input" type="text" onfocusin="(this.type='date')"
                            onfocusout="(this.type='text')" id="date">
                    </div>
                    <div class="voyageurs">
                        <fa-icon class="" [icon]=" faUserGroup"></fa-icon>
                        <input type="text" class="search-input" placeholder="Voyageurs">
                    </div>
                    <button class="search-icon"> <fa-icon [icon]="faSearch"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="advert-section">
    <div class="title">
        Les dernières adverts
        <p class="subtitle">Vous aussi soyez sur cette page en postant une advert</p>
    </div>
    <div class="adverts-container">
        <div *ngFor="let advert of adverts" class="card">
            <div [ngClass]="{
                'header-card-date-dest': advert.destination,
                'header-card-date': !advert.destination
                }">
                <div *ngIf="advert.destination" class="tag">
                    <div class="flag">
                        <img src="assets/drapeau-europe/france.svg.png" alt="Drapeau du pays de destination"
                            height="12">
                    </div>
                    <div id="destinationadvert">{{advert.destination}}</div>
                </div>
                <div id="datePublication">{{advert.createdAt}}</div>
            </div>
            <div class="content-card">
                <div class="title-advert" id="titleadvert">{{advert.title}}</div>
                <div class="content-advert" id="contentadvert">{{advert.description}}</div>
            </div>
            <div class="footer-card">
                <div class="user">
                    
                    <img *ngIf="advert.user.picture" src={{advert.user.picture}} alt="pictureProfil" class="picture-profile">
                    <img *ngIf="!advert.user.picture" src="/src/assets/user-icon.png" alt="pictureProfil" class="picture-profile">
                    <div class="name-user">{{advert.user.name}}</div>
                </div>
                <div class="tags">
                    <div class="tag">
                        <fa-icon [icon]="faUserGroup"></fa-icon>
                        <div class="number">{{advert.numberTrippers}}</div>
                    </div>
                    <div *ngIf="advert.toll" class="tag">
                        <fa-icon [icon]="faRoadBarrier"></fa-icon>
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </div>
                    <div *ngIf="!advert.toll" class="tag">
                        <fa-icon [icon]="faRoadBarrier"></fa-icon>
                        <fa-icon [icon]="faXmark"></fa-icon>
                    </div>
                    <div *ngFor="let typeTag of advert.tags">
                        <div class="tag">
                            <div class="">{{typeTag}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-section">
        <button  class="button-other" (click)="redirectPath('/adverts')">
            Découvrir toutes les adverts
        </button>
    </div>
</div>

<!-- 
<div class="section presentation-section">
    <div class="container presentation-container">
        <div class="presentation-wrapper">
            <h1 class="presentation-title">
                Les voyages à plusieurs
            </h1>
            <p>
                VoyCamp est un espace de partage pour créer vos voyages personnalisés en toute simplicité
            </p>
            <p>
                Voycamp est une application permmettant de mettre en relation des voyageurs en camping car et en
                caravane
                qui
                souhaite voyager en groupe
            </p>
            <p>
                Vous souhaitez consulter les voyages déjà en ligne
            </p>
        </div>
    </div>
</div>

<div class="section triptic-section">
    <div class="container triptic-container">
        <h2 id="triptic-title">Découvrez votre voyage collaboratif</h2>
        <div class="triptic-content">
            <div class="triptic-card">
                <div class="triptic-header">
                    <div class="icon-container">
                        <fa-icon class="triptic-icon " [icon]="faComments"></fa-icon>
                    </div>
                    <h4 class="triptic-title">Discussion</h4>
                </div>
                <div class="triptic-desc">Proposez une destination, discutez avec les autres campeurs, organisez votre
                    voyage
                    ...</div>
            </div>
            <div class="triptic-card">
                <div class="triptic-header">
                    <div class="icon-container">
                        <fa-icon class="triptic-icon" [icon]="faCaravan"></fa-icon>
                        <fa-icon class="triptic-icon" [icon]="faCarSide"></fa-icon>
                    </div>
                    <h4 class="triptic-title">Voyage</h4>
                </div>
                <div class="triptic-desc">Suivez l'itinéraire que vous avez établie ou alors poursuivez vos envies.
                    Tout en ayant les bons plans des autres campeurs.
                </div>
            </div>
            <div class="triptic-card">
                <div class="triptic-header">
                    <div class="icon-container">
                        <fa-icon class="triptic-icon" [icon]="faHandShake"></fa-icon>
                    </div>
                    <h4 class="triptic-title">Partage</h4>
                </div>
                <div class="triptic-desc">Vous avez appréciez certains point de votre voyage ? Partagez le avec les
                    autres
                    campeurs.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section creation-compte-section">
    <div class="container creation-compte-container">
        <div class="creation-compte-wrapper">
            <h2 id="creation-compte-title"> Pourquoi ne pas tenter l'expérience ? </h2>
            <p>Rejoignez la communauté de campeurs présent et découvrez le partage, la sympathie, la collaboration entre
                VoyCampeurs</p>
            <div id="button-container">
                <button class="creation-compte-button" (click)="redirectPath('/  onnection() {
')">Créer votre compte</button>
            </div>
        </div>
    </div>
</div>

<div class="section pricing-section">
    <div class="container pricing-container">
        <h2>Abonnement</h2>
        <div class="pricing-content">
            <div class="card pricing-free">
                <h3>Basique</h3>
                <div>
                    <ul>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Poster une proposition de voyage
                        </li>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Proposition aléatoire d'un voyage
                        </li>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Discussion entre campeurs
                        </li>
                    </ul>
                </div>
                <div class="footer-card">
                    <p class="price">0 €</p>
                </div>
            </div>
            <div class="card pricing-prenium">
                <h3>Prenium</h3>
                <div>
                    <ul>
                        <li class="oldFunction">Poster une proposition de voyage</li>
                        <li class="oldFunction">Proposition aléatoire d'un voyage</li>
                        <li class="oldFunction">Discussion entre campeurs</li>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Accès aux proposition de voyages des
                            membres
                        </li>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Organiser votre propre itinéraire
                        </li>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Accès aux offres des campings
                            partenaire
                        </li>
                        <li>
                            <fa-icon class="newFunction" [icon]="faStar"></fa-icon>Créer un groupe de campeurs
                        </li>
                    </ul>
                </div>
                <div class="footer-card">
                    <p class="price">7,90 €</p>
                </div>
            </div>
        </div>
    </div>
</div> -->