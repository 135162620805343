<div class="login-component">
    <form class="login-form">
        <div class="login-container">
            <h1>Renseignez vos informations de connexion</h1>

            <!-- <label for="login">Identifiant : </label> -->
            <input type=text name="login" id="login" class="input-login" placeholder="Adresse mail" required>
            <!-- <label for="password">Mot de passe : </label> -->
            <input type="password" name="password" id="password" class="input-login" placeholder="Mot de passe"
                required>

            <!-- <a routerLink="/Dashboard" (click)="connexion()" class="button-style">Connexion</a> -->
            <button (click)="Connection()" class="button-style" [disabled]="loginForm">Connexion</button>
            <a routerLink="/MotDePasse">Mot de passe oublié</a>

        </div>
    </form>
</div>