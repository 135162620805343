<div class="search-bar">
    <div class="search-entries">
        <div class="destination">
            <fa-icon [icon]="faFlag" />

            <!-- <input type="text" class="search-input" placeholder="Pays" value="{{seachBar.country}}"> -->
            <!-- <select name="country" (change)="OnSelectedCountryDestination($event)"> -->
            <select name="country" class="select-destination" title="{{seachBar.destination.country.name}}"
                (change)="OnSelectedCountryDestination($event)">
                <option appEllipsis [maxLength]="20" *ngFor="let country of countries" value="{{country.name}}"
                    title="{{country.name}}">{{country.name}}</option>
            </select>
            <select name="region" class="select-destination" (change)="OnSelectedRegionDestination($event)">
                <option *ngFor="let region of regions " value="{{region.name}}">{{region.name}}</option>
            </select>
            <select name="city" class="select-destination" (change)="OnSelectedCityDestination($event)">
                <option *ngFor="let city of cities " value="{{city}}">{{city.name}}</option>
            </select>
        </div>


        <div class="dates">
            <fa-icon [icon]="faCalendarDays" />
            <input placeholder="Départ" class="search-input" type="text" onfocusin="(this.type='date')"
                onfocusout="(this.type='text')" id="date-start" value="{{seachBar.date.start}}">
            <span class="search-input">|</span>
            <input placeholder="Retour" class="search-input" type="text" onfocusin="(this.type='date')"
                onfocusout="(this.type='text')" id="date-end" value="{{seachBar.date.end}}">
        </div>
        <div>
            <fa-icon class="" [icon]="faUserGroup" />

            <input type="number" alt="Vous êtes combien ?" name="NombreDeVoyageur" value="{{seachBar.usTrippers}}"
                placeholder="Nombre de voyageur à la recherche">
            <!-- TODO -->
        </div>

    </div>
    <button class="search-icon" (click)="submitSearchBar()"> <fa-icon [icon]="faSearch" /></button>
</div>