import { Directive, Input, ElementRef, AfterViewInit, SimpleChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appEllipsis]'
})
export class EllipsisDirective implements AfterViewInit {
  @Input() maxLength: number = 50; // Vous pouvez ajuster cette limite selon vos besoins
  @Input() content: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      this.ellipsisText();
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.ellipsisText();
  }

  private ellipsisText() {
    if (!this.content) return;
    
    let truncatedText = this.content.length > this.maxLength 
      ? this.content.substring(0, this.maxLength) + '...' 
      : this.content;

    this.renderer.setProperty(this.el.nativeElement, 'innerText', truncatedText);
  }
}
