import { Component, OnInit } from '@angular/core'
import { AdvertDisplayed } from 'src/models/advert'
import { faCaravan, faCarSide, faSearch, faStar, faCalendarDays, faLocationDot, faUserGroup, faRoadBarrier, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faComments, faHandshake } from '@fortawesome/free-regular-svg-icons'
import { Router } from '@angular/router'


@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
	faSearch = faSearch
	faLocationDot = faLocationDot
	faUserGroup = faUserGroup
	faComments = faComments
	faCaravan = faCaravan
	faCarSide = faCarSide
	faHandShake = faHandshake
	faRoadBarrier = faRoadBarrier
	faCalendarDays = faCalendarDays
	faCheck = faCheck
	faXmark = faXmark
	faStar = faStar
	adverts: Array<AdvertDisplayed> = []

	constructor(private router: Router) { }

	ngOnInit(){
		this.adverts = [
			{
				id: '165ZUHD8Y29',
				title: 'Vacances toutes décompression aux travers des montagnes',
				description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cen',
				numberTrippers: 4,
				dateTrip: {
					start: new Date('12/12/2014').toLocaleDateString('fr-FR'),
					end: new Date('18/04/2015').toLocaleDateString('fr-FR'),
				},
				destination: {country:{name:'France', isoCode:''}},
				user: {
					name: 'Marie',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: []
				},
				tags: ['Naturiste', 'Ecologiste'],
				toll: true,
				createdAt: new Date().toLocaleDateString('fr-FR'),
			},
			{
				id: 'enfzun678kjzb89',
				title: 'Titre Deuxième annnonce',
				description: 'Contenu de la deuxième annonce',
				destination:{country:{name:'Suisse', isoCode:'//TODO'}},
				numberTrippers: 70,
				dateTrip: {
					start: undefined,
					end: undefined,
				},
				user: {
					name: 'François',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: []
				},
				tags: ['Joyeux'],
				toll: true,
				createdAt: new Date().toLocaleDateString('fr-FR')
			},
		]
	}

	redirectPath(path:string) {
		this.router.navigateByUrl(path)
	}

}
