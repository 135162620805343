import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { Observable, map } from "rxjs";
import { Message } from "../type/chat";

@Injectable()
export class ChatService {
  constructor(private socket: Socket) {
    this.socket.connect();
    console.log("WebChat connection", this.socket);
  }

  sendMessage(message: string) {
    // console.log("SendMESAGE : ", message);
    this.socket.emit("sendMessage", { data: message});
  }

  getUsers(): Observable<number>{
    return this.socket.fromEvent('users');
  }

  getNewMessage(): Observable<Message> {
    return this.socket.fromEvent("receiveMessage").pipe(
      map((data: any) => {
        // console.log("JE RECOIS LES MESSGAES" , data.data)
        return data.data;
      })
    );
  }
}
