<div class="register-component">
    <h1>Inscription</h1>
    <button class="close-button" (click)="closeDialog()">X</button>
    <form [formGroup]="registrationForm" (submit)="registration()" class="register-form">
        <div class="register-container">

            <div class="login-info">
                <h3 class="title-part" i18n>Informations de connexion</h3>
                <div class="content-part">
                    <mat-form-field>
                        <mat-label i18n>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-error
                            *ngIf="registrationForm.get('email')?.hasError('email') && !registrationForm.get('email')?.hasError('required')"
                            i18n>
                            Merci de renseigner une adresse email valide
                        </mat-error>
                        <mat-error *ngIf="registrationForm.get('email')?.hasError('required')" i18n>
                            L'email est <strong>obligatoire</strong>
                        </mat-error>
                    </mat-form-field>
                    <div class="passwords">
                        <mat-form-field>
                            <mat-label i18n>Mot de passe</mat-label>
                            <input matInput type="password" formControlName="password">
                            <mat-error *ngIf="registrationForm.get('password')?.hasError('required')" i18n>
                                Le mot de passe est <strong>obligatoire</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Confirmation mot de passe</mat-label> 
                            <input matInput type="password" formControlName="confirmPassword">
                            <mat-error *ngIf="registrationForm.get('confirmPassword')?.hasError('required')" i18n>
                                La confirmation est <strong>obligatoire</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="about-user">
                <h3 class="title-part" i18n>A propos de vous</h3>
                <div class="content-part">
                    <div class="inputs">
                        <mat-form-field>
                            <mat-label>Nom</mat-label>
                            <input matInput formControlName="name">
                            <mat-error *ngIf="registrationForm.get('name')?.hasError('required')" i18n>
                                Un nom est <strong>obligatoire</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n>Langue Natale</mat-label>
                            <mat-select required (selectionChange)="onLanguageChange($event)">
                                <mat-option *ngFor="let language of languagesValues" [value]="language.isoCode">
                                    <span class="fi fi-{{language.isoCode.split('-')[0]| lowercase}}"></span>
                                    {{language.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <section *ngIf="otherLanguagesValues.length > 0" class="languages">
                        <h4 i18n>Autres langues que vous parlez :</h4>
                        <mat-checkbox *ngFor="let language of otherLanguagesValues"
                            [checked]="otherLanguageChecked.includes(language)"
                            (change)="onLanguageCheckboxChange($event, language)">
                            <span class="fi fi-{{language.isoCode.split('-')[0] | lowercase}}"></span>
                            {{language.name}}
                        </mat-checkbox>
                    </section>
                </div>
            </div>
            <button type="submit" class="button-style" [disabled]="!registrationForm" i18n>Valider l'inscription</button>
        </div>
    </form>

    <div *ngIf="error" class="error-message" role="alert" i18n>
        Une erreur est survenue
    </div>
    <div *ngIf="success" role="alert" i18n>
        Votre compte a bien été créé
    </div>
    <div *ngIf="passwordNotEqual" role="alert" i18n>
        Les mots de passe ne sont pas identiques
    </div>

</div>