import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ChangeContext, Options, PointerType } from 'ngx-slider-v2'
import {
	faSearch,
	faRoadBarrier,
	faCheck,
	faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { AdvertDisplayed, SearchBar } from 'src/models/advert'
import { tagsAdvert } from 'src/constants/tags'

@Component({
	selector: 'app-filter-advert',
	templateUrl: './filter-advert.component.html',
	styleUrls: ['./filter-advert.component.scss'],
})
export class FilterAdvertComponent implements OnInit {
	faSearch = faSearch
	faCheck = faCheck
	faXmark = faXmark
	faRoadBarrier = faRoadBarrier

	@Input({ required: true }) advertToFilter: AdvertDisplayed[] = []
	@Input() searchBarData: SearchBar = {
		destination: {
			country: { isoCode: '', name: '' },
		},
		date: {},
		usTrippers: 0,
	}
	@Output() advertsFiltered = new EventEmitter<AdvertDisplayed[]>()

	optionTollSelected: boolean | null = null
	tollYesSelected: boolean = false
	tollNoSelected: boolean = false

	tagsFilter: string[] = tagsAdvert
	tagsFilterSelected: string[] = []

	seachBar: SearchBar = {
		// country: "",
		// region: "",
		// city: "",
		destination: { country: { isoCode: '', name: '' } },
		date: {},
		usTrippers: 0,
	}

	sliderTrippersValues: { max: number; min: number } = { max: 0, min: 0 }
	sliderTrippersValuesSelected: { max: number; min: number } = {
		max: 0,
		min: 0,
	}
	optionsSliderNumberTripper: Options = {
		floor: 0,
		ceil: 0, //is setup with setNewCeil
	}

	sliderApproxStartDayValue: { max: number; min: number } = { max: 0, min: 0 }
	sliderApproxStartDayValuesSelected: { max: number; min: number } = {
		max: 0,
		min: 0,
	}
	optionsSliderApproxStartDay: Options = {
		floor: 0,
		ceil: 7,
	}

	ngOnInit() {
		this.setValueSlidderTrippers(this.advertToFilter)
	}

	OnNumberTripperChange(changeContext: ChangeContext): void {
		if (changeContext.pointerType === PointerType.Min) {
			this.sliderTrippersValuesSelected.min = changeContext.value
		} else {
			this.sliderTrippersValuesSelected.max =
				changeContext.highValue ?? this.sliderTrippersValues.max
		}
		this.filterAdverts()
	}

	OnSelectedTags(tag: string) {
		const index = this.tagsFilterSelected.indexOf(tag)

		if (index !== -1) {
			this.tagsFilterSelected.splice(index, 1)
		} else {
			this.tagsFilterSelected.push(tag)
		}

		this.filterAdverts()
	}

	OnSelectedToll(choice: string) {
		if (choice === 'toll-yes') {
			this.optionTollSelected = true
			this.tollNoSelected = false
		} else if (choice === 'toll-no') {
			this.optionTollSelected = false
			this.tollYesSelected = false
		}

		if (!this.tollYesSelected && !this.tollNoSelected)
			this.optionTollSelected = null

		this.filterAdverts()
	}

	filterAdverts() {
		let filteredAdverts = this.advertToFilter

		if (this.tagsFilterSelected.length > 0) {
			filteredAdverts = filteredAdverts.filter((advert) =>
				advert.tags.some((tag) => this.tagsFilterSelected.includes(tag))
			)
		}

		// Filtrer sur les valeurs min et max du composant ngx-slider
		if (
			this.sliderTrippersValuesSelected.min !==
				this.optionsSliderNumberTripper.floor ||
			this.sliderTrippersValuesSelected.max !==
				this.optionsSliderNumberTripper.ceil
		) {
			filteredAdverts = filteredAdverts.filter(
				(advert) =>
					advert.numberTrippers >= this.sliderTrippersValuesSelected.min &&
					advert.numberTrippers <= this.sliderTrippersValuesSelected.max
			)
		}

		// Filtrer sur la propriété toll si l'optionTollSelected est définie
		if (this.optionTollSelected !== null) {
			filteredAdverts = filteredAdverts.filter(
				(advert) => advert.toll === this.optionTollSelected
			)
		}

		if (
			this.tagsFilterSelected.length === 0 &&
			this.optionTollSelected === null &&
			this.sliderTrippersValuesSelected.min ===
				this.optionsSliderNumberTripper.floor &&
			this.sliderTrippersValuesSelected.max ===
				this.optionsSliderNumberTripper.ceil
		) {
			this.advertsFiltered.emit(this.advertToFilter)
			return
		}

		this.advertsFiltered.emit(filteredAdverts)
	}

	setValueSlidderTrippers(adverts: AdvertDisplayed[]) {
		const numberTrippersValues = adverts.map((ad) => ad.numberTrippers || 0)

		//TODO REFACTO CETTE PARTIE LA
		if (adverts) {
			this.sliderTrippersValues.max = Math.max(...numberTrippersValues)
			this.sliderTrippersValues.min = Math.min(...numberTrippersValues)
			this.sliderTrippersValuesSelected.max = this.sliderTrippersValues.max
			this.sliderTrippersValuesSelected.min = this.sliderTrippersValues.min

			this.optionsSliderNumberTripper = this.setNewCeil(
				this.sliderTrippersValues.max,
				this.optionsSliderNumberTripper
			)
			this.optionsSliderApproxStartDay = this.setNewCeil(
				this.sliderTrippersValues.max,
				this.optionsSliderApproxStartDay
			)
		}
	}

	setNewCeil(newCeil: number, options: Options): Options {
		//ngx-slider component
		// Due to change detection rules in Angular, we need to re-create the options object to apply the change
		const newOptions: Options = Object.assign({}, options)
		newOptions.ceil = newCeil
		options = newOptions
		return options
	}
}
