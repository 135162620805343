import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './public/login/login.component'
import { HomepageComponent } from './public/homepage/homepage.component'
import { ContactComponent } from './public/contact/contact.component'
import { LegalMentionComponent } from './public/legal-mention/legal-mention.component'
import { RegistrationComponent } from './public/registration/registration.component'
import { AdvertComponent } from './private/advert/view-advert/advert.component'
import { ChatComponent } from './private/chat/chat.component'
import { PublishAdvertComponent } from './private/advert/publish-advert/publish-advert.component'

const routes: Routes = [
	{ path: '', component: HomepageComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'registring', component: RegistrationComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'mentions-legales', component: LegalMentionComponent },
	{ path: 'adverts', component: AdvertComponent },
	{ path: 'chat', component: ChatComponent },
	{ path: 'publication', component: PublishAdvertComponent },
  
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
