import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ChatService} from "src/app/services/chat.service";
import {Conversations, DetailContact, Message} from "src/app/type/chat";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  chatForm = new FormGroup({
    message: new FormControl<string>(""),
  });

  public messages: Message[] = [
    {message: "Salut, ça va ?", isMe: false, datetime: new Date(), anwser: false},
    {message: "Tu fais quoi ce weekend ?", isMe: false, datetime: new Date(), anwser: false},
    {message: "ça va et toi ?", isMe: true, datetime: new Date(), anwser: false},
    {message: "Je baise ta mère, tranquillement", isMe: true, datetime: new Date(), anwser: false},
    {message: "Ah ok, sympa", isMe: false, datetime: new Date(), anwser: false},
    {
      message: "Regarde, j'écris un gros texte pour tester ce que ça fait sur deux ligne",
      isMe: true,
      datetime: new Date(),
      anwser: false
    },
    {
      message: "Ah ouais stylé ! Moi aussi j'essaie, comme ça on est sûr que ça marche bien",
      isMe: false,
      datetime: new Date(),
      anwser: false
    },
  ];
  users: number = 0;

  conversations: Conversations[] = [];//TODO dans le back
  detailContact: DetailContact = {
    name: "Joseph Tourcoin",
    country: "France",
    notes: ["C'est un sacré bavard", "Il aime le fromage", "freh rk  k  hg yg yg yg yg yg k k k l lk lk  ju jhfz ig i iug ug u lk lk lk lk lk lk lk lkl k lk"],
    flag: "assets/drapeau-europe/france.svg.png",
    picture: "src/assets/fond2.jpeg"
  };//TODO dans le back

  constructor(private chatService: ChatService) {
  }

  ngOnInit() {
    this.chatService
      .getNewMessage()
      .subscribe((message: Message) => this.messages.push(message));

    this.chatService
      .getUsers()
      .subscribe((users: number) => this.users = users);


    this.conversations.push({
      name: "Name1",
      lastMessage: "Je t'envoie ce message, pour bref a a a a a a a a a a hy h j jj j j j j j j j j j j j"
    }, {name: "Name2", lastMessage: "Je t'envoie ce message, pour bref"});//TODO dans le back
  }


  onSubmit() {
    let message: any = this.chatForm.value["message"];
    // console.log("Onsubmit", message); //DELETE
    if (!message) {
      return;
    }
    this.messages.push(message)
    this.chatService.sendMessage(message);
    this.chatForm.reset();
  }

  public messageSenderHasChanged(messages: Message[], index: number): boolean {
    if (index > 0 && messages[index].isMe !== messages[index - 1].isMe) {
      return true;
    }
    return false;
  }
}
