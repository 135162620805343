import { Component, OnDestroy, OnInit } from '@angular/core'
import {
	faCalendarDays,
	faUserGroup,
	faRoadBarrier,
	faCheck,
	faXmark,
	faPaperPlane,
	faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { AdvertDisplayed, SearchBar } from 'src/models/advert'
import { AdvertService } from '../advert.service'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-advert-dashboard',
	templateUrl: './dashboard-advert.component.html',
	styleUrls: ['./dashboard-advert.component.scss'],
})
export class DashboardAdvertComponent implements OnInit, OnDestroy {
	faCalendarDays = faCalendarDays
	faUserGroup = faUserGroup
	faCheck = faCheck
	faXmark = faXmark
	faRoadBarrier = faRoadBarrier
	faPaperPlane = faPaperPlane
	faArrowRight = faArrowRight

	advertsDisplayed: AdvertDisplayed[] = []
	advertsFiltered: AdvertDisplayed[] = []
	subscription?: Subscription

	searchBar: SearchBar = {destination:{country:{
		name: '',
		isoCode: ''
	}},date:{}, usTrippers:0,}

	constructor(private advertService: AdvertService) {}

	ngOnInit() {
		this.GetAdvert()
	}

	GetAdvert(searchBarData?: SearchBar) {
		//TODO Filter advert (with back) with parameter of searchBarData
		this.subscription = this.advertService.getAdvert().subscribe({
			next: (adverts) => {
				this.advertsDisplayed = adverts.map((ad) => ({
					...ad,
					tags: ad.tags.slice(0, 3).concat(ad.tags.length > 3 ? ['...'] : []),
					createdAt: ad.createdAt.toLocaleDateString('fr-FR'),
					dateTrip: {
						start: ad.dateTrip?.start
							? new Date(ad.dateTrip.start).toLocaleDateString('fr-FR')
							: undefined,
						end: ad.dateTrip?.end
							? new Date(ad.dateTrip.end).toLocaleDateString('fr-FR')
							: undefined,
					},
				}))
				this.advertsFiltered = this.advertsDisplayed
			},
			error: (error) => {
				console.error('Error fetching adverts', error)
			},
		})
	}

	setFilteredAdverts(adverts:AdvertDisplayed[]){    
		this.advertsFiltered = adverts
	}

	setSearchBarData(searchBarData: SearchBar){
		console.log('Je set la SearchBar')
		console.log(searchBarData)
		this.GetAdvert()
    
		this.searchBar = searchBarData
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe()
		}
	}

	
}
