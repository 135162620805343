import { Component, OnInit } from '@angular/core';

import axios from 'axios'
import { environment } from 'src/environments/environment'



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isConnected: boolean = false;

  constructor() { }

  async ngOnInit() {
    const jwtTokenStored = localStorage.getItem('authToken');
    if (jwtTokenStored !== null) {
      try {
        await axios.get(`${environment.apiURL}/auth/check-logged`, {
          headers: {
            'Authorization': `Bearer ${jwtTokenStored}`
          }
        });
        this.isConnected = true;
      } catch {
        localStorage.removeItem('authToken');
        this.isConnected = false;
      }
    }
  }

}
