import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PublicService } from '../public.service';
import { LoginDTO, LoginForm } from 'src/models/user';
import { RegistrationComponent } from '../registration/registration.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup<LoginForm>({
    email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  })

  errorLogIn = false;

  constructor(
    private publicService: PublicService,
    public loginDialogRef: MatDialogRef<LoginComponent>,
    public registerDialogRef: MatDialogRef<RegistrationComponent>

  ) { }
  // ngOnChanges(changes: SimpleChanges): void {
  //   this.loginForm.valueChanges.subscribe(val => console.log(val));
  //   console.log(this.loginForm.valueChanges.subscribe());
  // }

  ngOnInit(): void {
    this.loginForm.valueChanges;
  }

  async login() {
    if (this.loginForm.valid) {
      const loginUserData: LoginDTO= {
        email: this.loginForm.value.email?.toLocaleLowerCase() as string,
        password: this.loginForm.value.password as string
      }
      const response = await this.publicService.login(loginUserData)
      if(response.status === 200){
        localStorage.setItem('authToken', response.data.access_token);
        window.location.reload();
      }
      else{
        this.errorLogIn = true;
      }
    }
  }

  closeLoginDialog(): void {
    this.loginDialogRef.close();
  }

  goToRegisterForm(){
    this.closeLoginDialog();
    this.publicService.openRegisterDialog();
  }
  
}
