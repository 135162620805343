import { Injectable } from '@angular/core'
import axios from 'axios'
import { environment } from 'src/environments/environment'
import { RegistrationDTO } from 'src/models/user'

@Injectable({
	providedIn: 'root'
})
export class PublicService {
	constructor() { }

	async registration(registerUserData: RegistrationDTO) {
		try{
			const response = await axios.post(`${environment.apiURL}/user/registration`, registerUserData)
			console.log('publicService - regisration : ', response)
			return response.status
		}catch(error : any){
			console.error('publicService - regisration : ', error)
			return error.response.status
			// return error.response.status
		}

	}
}