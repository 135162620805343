<div class="adverts-finder">
    <div class="search-section">
        <p>Où souhaitez-vous aller ?</p>
        <app-search-bar (searchBarParams)="setSearchBarData($event)"></app-search-bar>
        <pre class="results">Il y a <span class="advert-number">{{adverts.length}}</span> résultats</pre>
    </div>
    <div *ngIf="adverts.length>0" class="content-section">
        <app-filter-advert [advertToFilter]="adverts"
            (advertsFiltered)="setFilteredAdverts($event)"></app-filter-advert>
        <div class="adverts-section">
            <div *ngFor="let advert of advertsFiltered" class="card">
                <div [ngClass]="{
                    'header-card-date-dest': advert.destination,
                    'header-card-date': !advert.destination
                    }">
                    <div *ngIf="advert.destination" class="tag">
                        <div class="flag">
                            <!-- <span *ngIf="indexCountrySelected >= 0" alt="Drapeau du pays" i18n-alt class="fi fi-{{countries[indexCountrySelected].isoCode.toLocaleLowerCase()}}"></span> -->
                            <span class="fi fi-{{advert.destination.country.isoCode | lowercase}}"></span>
                        </div>
                        <span appEllipsis title="{{advert.destination.country}}"
                            [maxLength]="10">{{advert.destination.country.name}}</span>
                    </div>
                    <div *ngIf="advert.dateTrip.start" class="date-trip">
                        <fa-icon [icon]="faCalendarDays" />
                        <div>{{advert.dateTrip.start}}</div>
                        <fa-icon [icon]="faArrowRight" />
                        <div *ngIf="advert.dateTrip.end" class="">{{advert.dateTrip.end}}</div>
                        <div *ngIf="!advert.dateTrip.end">?</div>
                    </div>
                </div>
                <div class="content-card">
                    <div class="text-content">
                        <div class="title-advert">{{advert.title}}</div>
                        <div class="description-advert">
                            <span appEllipsis [maxLength]="150">
                                {{advert.description}}
                            </span>
                        </div>
                    </div>
                    <div class="more-info">
                        <div class="tags">
                            <div *ngFor="let tag of advert.tags" class="tag">
                                {{tag}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-card">
                    <div class="user">
                        <img src="../../../assets/user-icon.png" alt="pictureProfil" class="picture-profile">
                        <div class="name-user">{{advert.user.name}}</div>
                    </div>
                    <div class="other-tags">
                        <div class="tag number-trippers">
                            <fa-icon [icon]="faUserGroup" />
                            <span>{{advert.numberTrippers}}</span>
                        </div>
                        <div class="tag toll">
                            <fa-icon [icon]="faRoadBarrier" />
                            <fa-icon *ngIf="advert.toll" [icon]="faCheck" />
                            <fa-icon *ngIf="!advert.toll" [icon]="faXmark" />
                        </div>
                    </div>
                    <div class="first-contact-container">
                        <button class="first-contact-button"> <fa-icon [icon]="faPaperPlane" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>