import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { PublicService } from '../public.service'
import { RegistrationDTO, RegistrationForm } from 'src/models/user'
import { MatDialogRef } from '@angular/material/dialog'
import { Language } from 'src/app/type/language'
import { MatSelectChange } from '@angular/material/select'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
	selector: 'app-registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

	constructor(
		private publicService: PublicService,
		public dialogRef: MatDialogRef<RegistrationComponent>
	) { }

	ngOnInit(): void {
	}

	registrationForm = new FormGroup<RegistrationForm>({
		email: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
		name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		password: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		confirmPassword: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		natalLanguage: new FormControl<Language>({ isoCode: '', name: '' }, { nonNullable: true, validators: Validators.required }),
		picture: new FormControl<string | null>('/src/assets/user-icon.png'), //TODO: Add validators (required, image format, etc.) and remove null
	})

	
	emailAlreadyExists: boolean = false
	error: boolean = false
	success: boolean = false
	passwordNotEqual: boolean = false

	languagesValues = [
		{ isoCode: "fr-FR", name: "Français" },
		{ isoCode: "it-IT", name: "Italiano" },
		{ isoCode: "es-ES", name: "Español" },
		{ isoCode: "de-DE", name: "Deutsch" },
		{ isoCode: "gb", name: "English" },
	]

	otherLanguagesValues: Language[] = []
	otherLanguageChecked: Language[] = []

	checkEqualPassword(): boolean {
		return this.registrationForm.value.password === this.registrationForm.value.confirmPassword
	}

	async registration() {
		if (this.checkEqualPassword()) {
			if (this.registrationForm.valid) {
				const registerUserData: RegistrationDTO = {
					email: this.registrationForm.value.email?.toLocaleLowerCase() as string,
					name: this.registrationForm.value.name as string,
					password: this.registrationForm.value.password as string,
					natalLanguage: this.registrationForm.value.natalLanguage as Language,
					otherLanguages: this.otherLanguageChecked,
					picture: this.registrationForm.value.picture as string
				}

				const responseStatus = await this.publicService.registration(registerUserData)
				if (responseStatus === 201) {
					this.success = true
					setTimeout(() => {
						this.closeDialog()
					}, 3000)
				}
				else {
					this.error = true
				}
			}
		}
		else {
			this.passwordNotEqual = true
		}

	}

	onLanguageChange(event: MatSelectChange): void {
		const selectedIsoCodeLanguage = event.value;
		const selectedLanguage = this.languagesValues.find(langue => langue.isoCode === selectedIsoCodeLanguage);
		if (selectedLanguage) {
			this.registrationForm.get('natalLanguage')?.setValue(selectedLanguage);
		} this.otherLanguagesValues = this.languagesValues.filter(language => language.isoCode !== selectedIsoCodeLanguage);
	}

	onLanguageCheckboxChange(event: MatCheckboxChange, language: Language): void {
		if (event.checked) {
			this.otherLanguageChecked.push(language);
		} else {
			this.otherLanguageChecked = this.otherLanguageChecked.filter(lg => lg.isoCode !== language.isoCode);
		}
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

}
