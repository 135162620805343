import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appEllipsis]'
})
export class EllipsisDirective implements AfterViewInit {
  @Input() maxLength: number = 50; // Vous pouvez ajuster cette limite selon vos besoins

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.ellipsisText();
  }

  private ellipsisText() {
    let text = this.el.nativeElement.innerText;
    
    if (text.length > this.maxLength) {
      text = text.substring(0, this.maxLength) + '...';
    }

    this.el.nativeElement.innerText = text;
  }
}
