<nav class="nav-bar">
    <div class="left">  
        <a class="logo-accueil" routerLink="/"><img class="img-logo-accueil" src="assets/LogoVoycamp.png"
                alt="LogoAccueil">Voycamp</a>
        <a routerLink="/adverts">Annonces</a>
        <a class="publish-button" routerLink="/publication">Publier une annonce</a>
    </div>
    <div class="right">
        <a class="connection-button" routerLink="/login">Connexion</a>
        <a class="register-button" routerLink="/registring">Inscription</a>
    </div>
</nav>