import { Component, inject, OnInit } from '@angular/core';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { PublicService } from '../public.service';
import { LoginComponent } from '../login/login.component';
import { RegistrationComponent } from '../registration/registration.component';


@Component({
  selector: 'app-menu-public',
  templateUrl: './menu-public.component.html',
  styleUrls: ['./menu-public.component.scss']
})
export class MenuPublicComponent {

  faPen = faPen;

  constructor(    public publicService: PublicService,
  ) { }


  openLogInDialog(){
    this.publicService.openLogInDialog()
  }

  openRegisterDialog(){
    this.publicService.openRegisterDialog()
  }
}