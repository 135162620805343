<div class="card">
    <div [ngClass]="{
    'header-card-date-dest': advertToDisplay.itinerary[0],
    'header-card-date': !advertToDisplay.itinerary[0]
    }">
        <div *ngIf="advertToDisplay.itinerary[advertToDisplay.itinerary.length-1].country.name != ''" class="tag">
            <div class="flag">
                <span class="fi fi-{{advertToDisplay.itinerary[advertToDisplay.itinerary.length-1].country.isoCode |
                    lowercase}}"></span>
            </div>
            <span appEllipsis [content]="advertToDisplay.itinerary[advertToDisplay.itinerary.length-1].country.name"
                [maxLength]="10">{{advertToDisplay.itinerary[advertToDisplay.itinerary.length-1].country.name}}</span>
        </div>
        <div *ngIf="advertToDisplay.dateTrip.start" class="date-trip">
            <fa-icon [icon]="faCalendarDays" />
            <div>{{advertToDisplay.dateTrip.start}}</div>
            <fa-icon [icon]="faArrowRight" />
            <div *ngIf="advertToDisplay.dateTrip.end" class="">{{advertToDisplay.dateTrip.end}}</div>
            <div *ngIf="!advertToDisplay.dateTrip.end">?</div>
        </div>
    </div>
    <div class="content-card">
        <div class="text-content">
            <div class="title-advert">
                <span appEllipsis [maxLength]="70" [content]="advertToDisplay.title">
                    <!--//TODO Title sur une ou deux lignes ? maxLength = 34 pour une ligne et 70 pour deux lignes-->
                    {{advertToDisplay.title}}
                </span>
            </div>
            <div class="description-advert">
                <span appEllipsis [maxLength]="100" [content]="advertToDisplay.description">
                    {{advertToDisplay.description}}
                </span>
            </div>
        </div>
        <div class="all-tags">

            <div class="more-info">
                <div *ngIf="advertToDisplay.tags && advertToDisplay.tags.length > 0" class="tags">
                    <div *ngFor="let tag of advertToDisplay.tags.slice(0, 3)" class="tag">
                        {{tag}}
                    </div>
                    <div *ngIf="advertToDisplay.tags.length-3 > 0" class="tag count-additional-tag"> +
                        {{advertToDisplay.tags.length-3}}</div>
                </div>
            </div>
            <div class="other-tags">
                <div class="tag number-trippers">
                    <fa-icon [icon]="faUserGroup" />
                    <span>{{advertToDisplay.numberVehicles}}</span>
                </div>
                <div class="tag toll">
                    <fa-icon [icon]="faRoadBarrier" />
                    <fa-icon *ngIf="advertToDisplay.toll" [icon]="faCheck" />
                    <fa-icon *ngIf="!advertToDisplay.toll" [icon]="faXmark" />
                </div>
                <div class="tag smoker">
                    <fa-icon [icon]="faSmoking" />
                    <fa-icon *ngIf="advertToDisplay.smoker" [icon]="faCheck" />
                    <fa-icon *ngIf="!advertToDisplay.smoker" [icon]="faXmark" />
                </div>
                <div class="tag pet">
                    <fa-icon [icon]="faPaw" />
                    <fa-icon *ngIf="advertToDisplay.pets" [icon]="faCheck" />
                    <fa-icon *ngIf="!advertToDisplay.pets" [icon]="faXmark" />
                </div>
            </div>
        </div>
    </div>
    <div class="footer-card">
        <div class="user" *ngIf="advertToDisplay.user">
            <img src="../../../assets/user-icon.png" alt="pictureProfil" class="picture-profile">
            <div class="name-user">{{advertToDisplay.user.name}}</div>
        </div>

        <div class="first-contact-container">
            <button class="first-contact-button" (click)="sendFirstContact()"> <fa-icon
                    [icon]="faPaperPlane" /></button>
        </div>
    </div>
</div>