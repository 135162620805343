import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdvertComponent } from './view-advert/advert.component'
import { PublishAdvertComponent } from './publish-advert/publish-advert.component'
import { FilterAdvertComponent } from './filter-advert/filter-advert.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxSliderModule } from 'ngx-slider-v2'
import { SharedModule } from 'src/app/modules/shared/shared.module'
import { SearchBarComponent } from './search-bar/search-bar.component'


import {MatTooltipModule} from '@angular/material/tooltip'
import {MatIconModule} from '@angular/material/icon'

@NgModule({
	declarations: [
		AdvertComponent,
		PublishAdvertComponent,
		FilterAdvertComponent,
		SearchBarComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		NgxSliderModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		SharedModule,
		MatTooltipModule,
		MatIconModule
	],
})
export class AdvertModule {}
