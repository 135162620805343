<div class="filter-section">
    <div class="container slider-container">
        <span class="filter-title">Nombre voyageurs</span>
        <div class="slider-value">
            <span class="slider-min">{{sliderTrippersValues.min}}</span>
            <div class="custom-slider">
                <ngx-slider [(value)]="sliderTrippersValues.min" [(highValue)]="sliderTrippersValues.max"
                    [options]="optionsSliderNumberTripper" (userChange)="OnNumberTripperChange($event)"></ngx-slider>
            </div>
            <span class="slider-max">{{sliderTrippersValues.max}}</span>
        </div>
    </div>
    <!-- <span class="slider-title">Nombre escales</span> --> <!--TODO quand y'aura le système d'escale-->
    <div class="container slider-container">
        <span class="filter-title">Nombre approx. jours date départ </span>
        <!-- TODO Not implemented -->

        <div class="slider-value">
            <span class="slider-min">{{sliderApproxStartDayValue.min}}</span>
            <div class="custom-slider">
                <ngx-slider [(value)]="sliderApproxStartDayValue.min" [(highValue)]="sliderApproxStartDayValue.max"
                    [options]="optionsSliderApproxStartDay"></ngx-slider>
            </div>
            <span class="slider-max">{{sliderApproxStartDayValue.max}}</span>
        </div>
    </div>
    <div *ngIf="searchBarData.date.end" class="container slider-container">
        <!-- TODO Not implemented -->
        <span class="filter-title">Nombre approx. jours date retour </span>
        <div class="slider-value">
            <span class="slider-min">{{sliderApproxStartDayValue.min}}</span>
            <div class="custom-slider">
                <ngx-slider [(value)]="sliderApproxStartDayValue.min" [(highValue)]="sliderApproxStartDayValue.max"
                    [options]="optionsSliderApproxStartDay"></ngx-slider>
            </div>
            <span class="slider-max">{{sliderApproxStartDayValue.max}}</span>
        </div>
    </div>
    <div class="container toll-container">
        <div class="filter-title">Autoroute <fa-icon [icon]="faRoadBarrier" /></div>
        <div class="toll-choice">
            <input type="checkbox" name="toll-choice" id="toll-yes" [(ngModel)]="tollYesSelected"
                (change)="OnSelectedToll('toll-yes')">
            <label class="tag" for="toll-yes">
                <fa-icon [icon]="faCheck" />
            </label>
            <input type="checkbox" name="toll-choice" id="toll-no" [(ngModel)]="tollNoSelected"
                (change)="OnSelectedToll('toll-no')">
            <label class="tag" for="toll-no">
                <fa-icon [icon]="faXmark" />
            </label>
        </div>
    </div>
    <div class="container tags-container">
        <div class="key-word">
            <span class="filter-title">
                Mots clefs
            </span>
            <div class="search-word-container">
                <div class="search-bar">
                    <input type="text" placeholder="Recherche ..."> <fa-icon [icon]="faSearch" class="search-icon" />
                </div>
            </div>
        </div>
        <div class="tags">

            <span *ngFor="let tag of tagsFilter">
                <input type="checkbox" name="{{tag}}" id="{{tag}}" (change)="OnSelectedTags(tag)" />
                <label class="tag" for="{{tag}}">{{tag}}</label>
            </span>
        </div>
    </div>

</div>