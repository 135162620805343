import { Component, OnInit } from '@angular/core'
import {
	faSearch,
	faVanShuttle,
	faPen,
} from '@fortawesome/free-solid-svg-icons'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'VoyCamp'

	constructor(private route: ActivatedRoute) { }

	faSearch = faSearch
	faVanShuttle = faVanShuttle
	faPen = faPen

	isHome: boolean = false

	ngOnInit() {
		this.route.url.subscribe((urlSegments) => {
			const routeSegment = urlSegments[0] ? urlSegments[0].path : ''

			if (routeSegment === '') {
				this.isHome = true
			}
		})
	}
}
