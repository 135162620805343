<div class="chat-component">
  <div class="list-conversations" id="list-conversations">
    <div *ngFor="let conversation of conversations" class="conversations">
      <p class="name-conv">
        {{conversation.name}}
      </p>
      <p class="last-mess">
        {{conversation.lastMessage}}
      </p>
    </div>
  </div>


  <div class="chat-section">
    <div class="chat-container">
      <div class="name-chat">{{detailContact.name}}</div>
      <div class="">

      </div>
      <div>Il y a {{users}} de connecté(s)</div>
      <div class="messages-container">
        <div *ngFor="let message of messages; let i = index" class="message" id="messages"
             [ngClass]="{'new-sender' : messageSenderHasChanged(messages, i),
             'owner-message' : message.isMe,
             'others-message': !message.isMe}"
        >
          <p>
            {{message.message}}
          </p>
        </div>
      </div>
      <form [formGroup]="chatForm" (ngSubmit)="onSubmit ()" class="register-form" id="input-area">
        <!-- <input type="text" name="message" formControlName="message" required> -->
        <textarea placeholder="Entrez votre mesagge" autofocus name="message" formControlName="message"
                  form="input-area" wrap="soft" required=""></textarea>
        <button [disabled]="!chatForm.valid" type="submit">Send</button>
      </form>
    </div>
  </div>

  <div class="detail-contact-section">
    <div class="detail-contact-container">

      <img class="profile-picture" src="assets/IMG_5800.JPG" alt="" height="100">
      {{detailContact.name}}
      <div>
        {{detailContact.country}}
        <img src="{{detailContact.flag}}" alt="" height="12">
      </div>
      <div class="notes">
        <u>
          <p>Notes :</p>
        </u>
        <p *ngFor="let note of detailContact.notes">
          - {{note}}
        </p>
      </div>
    </div>
  </div>
</div>