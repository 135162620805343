import { Component, OnInit } from '@angular/core'
import { AdvertDisplayed } from 'src/models/advert'
import { faCaravan, faCarSide, faSearch, faStar, faCalendarDays, faLocationDot, faUserGroup, faRoadBarrier, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faComments, faHandshake } from '@fortawesome/free-regular-svg-icons'
import { Router } from '@angular/router'


@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
	faSearch = faSearch
	faLocationDot = faLocationDot
	faUserGroup = faUserGroup
	faComments = faComments
	faCaravan = faCaravan
	faCarSide = faCarSide
	faHandShake = faHandshake
	faRoadBarrier = faRoadBarrier
	faCalendarDays = faCalendarDays
	faCheck = faCheck
	faXmark = faXmark
	faStar = faStar
	adverts: Array<AdvertDisplayed> = []

	constructor(private router: Router) { }

	ngOnInit() {
		this.adverts = [
			{
				id: '165ZUHD8Y29',
				title: 'Vacances toutes décompression aux travers des montagnes et aussi de la charcuterie',
				description:
					'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five cen',
				numberVehicles: 4,
				dateTrip: {
					start: new Date('12/12/2014').toLocaleDateString('fr-FR'),
					end: new Date('04/15/2015').toLocaleDateString('fr-FR'),
				},
				itinerary: [{ country: { name: 'France', isoCode: 'FR' } }, { country: { name: 'France', isoCode: 'FR' } }],
				user: {
					name: 'Gérard Manchot',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: [],
					description: '',
				},
				tags: ['Naturiste', 'Ecologiste', 'Vadrouilleur', 'Naturiste', 'Naturiste'],
				toll: true,
				smoker: false,
				pets: true,
				createdAt: new Date().toLocaleDateString('fr-FR'),
			},
			{
				id: 'enfzun678kjzb89',
				title: 'Titre Deuxième annnonce',
				description: 'Contenu de la deuxième annonce',
				itinerary: [{ country: { name: 'France', isoCode: 'FR' } }, { country: { name: 'England', isoCode: 'GB' } }],
				numberVehicles: 70,
				dateTrip: {
					start: undefined,
					end: undefined,
				},
				user: {
					name: 'François',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: [],
					description: '',
				},
				tags: ['Joyeux'],
				toll: true,
				smoker: false,
				pets: true,
				createdAt: new Date().toLocaleDateString('fr-FR')
			}, {
				id: 'enfzun678kjzb89',
				title: 'Titre Troisème annnonce',
				description: 'Contenu de la Troisème annonce',
				itinerary: [{ country: { name: 'France', isoCode: 'FR' } }, { country: { name: 'Australia', isoCode: 'AU' } }],
				numberVehicles: 70,
				dateTrip: {
					start: new Date('01/10/2023').toLocaleDateString('fr-FR'),
					end: undefined,
				},
				user: {
					name: 'Albert Reporter',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: [],
					description: '',
				},
				tags: ['Joyeux', 'Naturiste', 'Ecologiste'],
				toll: true,
				smoker: false,
				pets: true,
				createdAt: new Date().toLocaleDateString('fr-FR')
			}
		]
	}



	redirectPath(path: string) {
		this.router.navigateByUrl(path)
	}

}
