import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EllipsisDirective } from 'src/app/directive/ellipsis.directive'

@NgModule({
	declarations: [EllipsisDirective],
	imports: [CommonModule],
	exports: [EllipsisDirective]
})
export class SharedModule {}
