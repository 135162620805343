import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { PublicService } from '../public.service'
import { RegistrationDTO, RegistrationForm } from 'src/models/user'

@Component({
	selector: 'app-registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

	constructor(
		private router: Router,
		private publicService: PublicService
	) { }

	ngOnInit(): void {
	}

	registrationForm = new FormGroup<RegistrationForm>({
		email: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
		name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		password: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		confirmPassword: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		languages: new FormControl<string[]>([], { nonNullable: true, validators: Validators.required }),
		picture: new FormControl<string | null>('/src/assets/user-icon.png'), //TODO: Add validators (required, image format, etc.) and remove null
	})

	emailAlreadyExists: boolean = false
	error: boolean = false
	success: boolean = false




	async registration() {
		if (this.registrationForm.valid) {

			const registerUserData: RegistrationDTO = {
				email: this.registrationForm.value.email as string,
				name: this.registrationForm.value.name as string,
				password: this.registrationForm.value.password as string,
				confirmpassword: this.registrationForm.value.confirmPassword as string,
				languages: this.registrationForm.value.languages as string[],
				picture: this.registrationForm.value.picture as string
			}

			const responseStatus = await this.publicService.registration(registerUserData)
			if (responseStatus === 201) {
				this.success = true
				console.log('Registration successful', this.success)
				setTimeout(() => {
					console.log('Registration timer')
					this.router.navigate(['/login'])
				}, 3000)
			}
			else {
				if (responseStatus === 409) {
					this.emailAlreadyExists = true
				}
				else {
					this.error = true
				}
			}
		}
	}
}
