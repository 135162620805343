import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomepageComponent } from './public/homepage/homepage.component'
import { ContactComponent } from './public/contact/contact.component'
import { LegalMentionComponent } from './public/legal-mention/legal-mention.component'
import { DashboardAdvertComponent } from './private/advert/dashboard-advert/dashboard-advert.component'
import { ChatComponent } from './private/chat/chat.component'
import { PublishAdvertComponent } from './private/advert/publish-advert/publish-advert.component'
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { DetailComponent } from './private/advert/detail/detail.component'

const routes: Routes = [
	{ path: '', component: HomepageComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'mentions-legales', component: LegalMentionComponent },
	{ path: 'dashboard', component: DashboardAdvertComponent },
	{ path: 'chat', component: ChatComponent },
	{ path: 'publication', component: PublishAdvertComponent },
	{ path: 'detail', component: DetailComponent },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}


export class DefaultTitleStrategy extends TitleStrategy {
  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title !== undefined) {
      document.title = `VoyCamp - ${title}`;
    }
  }
}