import { Component } from '@angular/core';
import { faArrowRight, faCalendarDays, faCheck, faCrown, faPaw, faRoadBarrier, faSmoking, faUserGroup, faXmark } from '@fortawesome/free-solid-svg-icons';
import { typeAdvertDisplayed } from 'src/constants/advert';
import { AdvertDisplayed } from 'src/models/advert';
import { User } from 'src/models/user';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.scss'
})
export class DetailComponent {

  faUserGroup = faUserGroup
  faCheck = faCheck
  faXmark = faXmark
  faRoadBarrier = faRoadBarrier
  faPaw = faPaw
  faSmoking = faSmoking
  faCrown = faCrown
  faArrowRight = faArrowRight
  faCalendarDays = faCalendarDays


  trippers: User[] = [
    {
      id: "toto",
      languages: ['Français', 'Deutsch'],
      name: 'Gérard Manchot',
      picture: '../../../assets/user-icon.png',
      description: 'Aime les grandes banquises bien fraiches'
    },
    {
      id: "toto2",
      languages: ['Français', 'Italian'],
      name: 'Albert Reporter',
      picture: '../../../assets/user-icon.png',
      description: 'Fait attention au lait que toute la famille prend'
    },
  ]

  advertToDisplay: AdvertDisplayed = typeAdvertDisplayed
}
