import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
	faSearch,
	faRoadBarrier
} from '@fortawesome/free-solid-svg-icons'
import {
	CityAdvert,
	CountryAdvert,
	DateTripForm,
	DestinationForm,
	PublishAdvert,
	PublishAdvertForm,
	Region,
} from 'src/models/advert'

import { tagsAdvert } from 'src/constants/tags'
import { AdvertService } from '../advert.service'

@Component({
	selector: 'app-publish-advert',
	templateUrl: './publish-advert.component.html',
	styleUrls: ['./publish-advert.component.scss'],
})
export class PublishAdvertComponent implements OnInit {
	faSearch = faSearch
	faRoadBarrier = faRoadBarrier

	constructor(
    private advertService: AdvertService
	) {}

	tags: string[] = tagsAdvert
	tagsSelected: string[] = []
	tagsFilter: string[] = tagsAdvert


	regions: Region[] = []
	countries: CountryAdvert[] = []
	cities: CityAdvert[] = []

	indexCountrySelected: number = -1
	indexRegionSelected: number = -1
	indexCitiesSelected: number = -1

	optionTollSelected: boolean | null = null
	tollYesSelected: boolean = false
	tollNoSelected: boolean = false

	publishAdvertForm = new FormGroup<PublishAdvertForm>({
		title: new FormControl('', {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(50),
			],
		}),
		description: new FormControl(''),
		numberVehicles: new FormControl(1, {
			nonNullable: true,
			validators: Validators.required,
		}),
		destination: new FormGroup<DestinationForm>({
			country: new FormControl<CountryAdvert>(
				{ name: '', isoCode: '' },
				{
					nonNullable: true,
					validators: Validators.required,
				}
			),
			region: new FormControl(),
			city: new FormControl(),
		}),
		tags: new FormControl<string[]>([], {
			nonNullable: true,
		}), //TODO Validator ENUM
		toll: new FormControl(false, {
			nonNullable: true,
			validators: Validators.required,
		}),
		smoking: new FormControl(false, {
			nonNullable: true,
			validators: Validators.required,
		}),
		pets: new FormControl(false, {
			nonNullable: true,
			validators: Validators.required,
		}),
		dateTrip: new FormGroup<DateTripForm>({
			start: new FormControl(),
			end: new FormControl(),
		}),
	})

	ngOnInit(): void {
		this.InitDestinationsChoices()
	}

	isEmpty(value:any) {
		return (value == null || (typeof value === 'string' && value.trim().length === 0))
	}


	InitDestinationsChoices() {
		this.countries = this.advertService.getCountries()
		this.countries.unshift({ name: 'Pays', isoCode: '' })
		this.regions = [{ name: 'Région', countryCode: '', isoCode: '' }]
		this.cities = [{ name: 'Ville', countryCode: '', stateCode: '' }]
	}

	switchStepThree(event:string){
		console.log(event)
		
		const numberVehiclesControl = this.publishAdvertForm.get('numberVehicles')
		const tollControl = this.publishAdvertForm.get('toll')
		const smokingControl = this.publishAdvertForm.get('smoking')
		const petsControl = this.publishAdvertForm.get('pets')
	
		if (this.isEmpty(event)) {
			numberVehiclesControl?.enable()
			tollControl?.enable()
			smokingControl?.enable()
			petsControl?.enable()
		} else {
			numberVehiclesControl?.disable()
			tollControl?.disable()
			smokingControl?.disable()
			petsControl?.disable()
		}
	}

	//#region Filters
	// OnSelectedToll(event: any) {
	// 	const valueSelected = event.target.value
	// 	let valueParseToBool: boolean
	// 	valueSelected === 'Oui'
	// 		? (valueParseToBool = true)
	// 		: (valueParseToBool = false)

	// 	this.publishAdvertForm.patchValue({ toll: valueParseToBool })
	// }

	OnSelectedToll(choice: string) {
		if (choice === 'toll-yes') {
			this.optionTollSelected = true
			this.tollNoSelected = false
		} else if (choice === 'toll-no') {
			this.optionTollSelected = false
			this.tollYesSelected = false
		}

		if (!this.tollYesSelected && !this.tollNoSelected)
			this.optionTollSelected = null
	}


	OnSelectedTags(event: any) {
		const { tagsSelected } = this
		const valueSelected = event.target.value

		if (tagsSelected.includes(valueSelected)) {
			this.tagsSelected = tagsSelected.filter((tag) => tag !== valueSelected)
		} else {
			this.tagsSelected = [...tagsSelected, valueSelected]
		}

		const selectedTags = this.tagsSelected.map((indexedTag) => {
			const index = parseInt(indexedTag, 10)
			return this.tags[index]
		})

		this.publishAdvertForm.patchValue({ tags: selectedTags })
	}
	//#endregion Filters

	//#region Select Destination
	OnSelectedCountryDestination(event: any) {
		this.indexCountrySelected = this.countries.findIndex(
			(element) => element.name === event.target.value
		)

		//REGIONS
		this.regions = this.advertService.getRegionOfCountry(
			this.countries[this.indexCountrySelected].isoCode
		)

		this.publishAdvertForm
			.get('destination.country')
			?.patchValue(this.countries[this.indexCountrySelected])
	}

	OnSelectedRegionDestination(event: any) {
		this.indexRegionSelected = this.regions.findIndex(
			(element: any) => element.name === event.target.value
		)

		this.cities = this.advertService.getCitiesOfState(
			this.countries[this.indexCountrySelected].isoCode,
			this.regions[this.indexRegionSelected].isoCode
		)
		if (this.cities.length < 0)
			this.cities = [{ stateCode: '', name: 'Aucune ville', countryCode: '' }]

		this.publishAdvertForm
			.get('destination.region')
			?.patchValue(this.regions[this.indexRegionSelected])
	}

	OnSelectedCityDestination(event: any) {
		this.indexCitiesSelected = this.cities.findIndex(
			(element: any) => element.name === event.target.value
		)

		this.publishAdvertForm
			.get('destination.city')
			?.patchValue(this.cities[this.indexCitiesSelected])
	}
	//#endregion 

	PublishAdvert() {
		if (
			this.publishAdvertForm.valid &&
      this.publishAdvertForm.value.destination
		) {
			console.log(this.publishAdvertForm.value.numberVehicles)
			
			const dataAdvert: PublishAdvert = {
				title: this.publishAdvertForm.value.title as string,
				description: this.publishAdvertForm.value.description as string,
				destination: {
					country: this.publishAdvertForm.value.destination
						.country as CountryAdvert,
					region: this.publishAdvertForm.value.destination?.region,
					city: this.publishAdvertForm.value.destination?.city,
				},
				numberVehicles: this.publishAdvertForm.value.numberVehicles as number,
				user: {
					name: 'userTemp',
					picture: '../../../assets/user-icon.png',
					id: '',
					languages: [],
				},
				tags: this.publishAdvertForm.value.tags as string[],
				toll: this.publishAdvertForm.value.toll as boolean,
				dateTrip: {
					start: this.publishAdvertForm.value.dateTrip?.start
						? new Date(this.publishAdvertForm.value.dateTrip?.start)
						: undefined,
					end: this.publishAdvertForm.value.dateTrip?.end
						? new Date(this.publishAdvertForm.value.dateTrip?.end)
						: undefined,
				},
			}

			this.advertService.addAdvert(dataAdvert)
		}
	}
}
