import { Injectable } from '@angular/core'
import axios from 'axios'
import { City, Country, ICountry, State } from 'country-state-city'
import { Observable, from } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
	Advert,
	CityAdvert,
	CountryAdvert,
	type PublishAdvert,
	RegionAdvert,
	SearchBar,
} from 'src/models/advert'

@Injectable({
	providedIn: 'root',
})
export class AdvertService {
	private advertUrl = `${environment.apiURL}/advert`
	private countries: ICountry[] = Country.getAllCountries()


	getAdvert(searchBarData?: SearchBar): Observable<Advert[]> {
		return from(
			axios
				.get<Advert[]>(this.advertUrl)
				.then((response) => {
					response.data.forEach((advert) => {
						advert.createdAt = new Date(advert.createdAt)
					})
					return response.data
				})
				.catch((error) => {
					console.error('Error fetching adverts', error)
					throw error // You can handle errors according to your requirements
				})
		)
	}


	async addAdvert(dataAdvert : PublishAdvert) {
		try {
			const response = await axios.post(this.advertUrl, dataAdvert)
			return response.data
		} catch (error) {
			// throw error;
		}
	}

	getAdvertFiltered(searchBarData: SearchBar): Observable<Advert[]> {
		return from(
			axios
				.get<Advert[]>(this.advertUrl + '/parameters', {
					params: searchBarData,
				})
				.then((response) => {
					response.data.forEach((advert) => {
						advert.createdAt = new Date(advert.createdAt)
					})
					return response.data
				})
				.catch((error) => {
					console.error('Error fetching adverts', error)
					throw error // You can handle errors according to your requirements
				})
		)
	}

	//#region Country-Region-City
	//Country-Region-City
	getCountries(): CountryAdvert[] {
		return this.countries
	}

	getRegionOfCountry(countryIsoCodeSelected: string): RegionAdvert[] {
		let regionsResult = State.getStatesOfCountry(countryIsoCodeSelected)
		if (countryIsoCodeSelected === 'FR') {
			const regionToRemove = ['CP', 'WF', 'MF', 'TF', 'PM', 'BL', 'MF']
			//Remove all isoCodeRegion where is number and island with no cities
			regionsResult = regionsResult.filter(
				(rg: RegionAdvert) =>
					/^[A-Za-z]+$/.test(rg.isoCode) && !regionToRemove.includes(rg.isoCode)
			)
		}

    
		if (regionsResult.length <= 0) {
			regionsResult = [{ isoCode: 'NONE', name: 'Aucune région', countryCode: 'NONE' }]
			const regionSelectedIsoCode = regionsResult[0].isoCode
			this.getCitiesOfState(countryIsoCodeSelected, regionSelectedIsoCode)
		}
		else{
			const regionSelectedIsoCode = regionsResult[0].isoCode
			this.getCitiesOfState(countryIsoCodeSelected, regionSelectedIsoCode)
			regionsResult.unshift({ name: 'Pas de préférence', countryCode: 'UND', isoCode: 'UND' })
		}

		return regionsResult
	}

	getCitiesOfState(countryCode: string, regionCode: string): CityAdvert[] {
		let citiesResult = City.getCitiesOfState(countryCode, regionCode)
		if (citiesResult.length <= 0) {
			citiesResult = [{ stateCode: 'NONE', name: 'Aucune ville', countryCode: 'NONE' }]
		}
		citiesResult.unshift({ name: 'Pas de préférence', countryCode: 'UND', stateCode: 'UND' })
		return citiesResult
	}
	//#endregion
}
