<div class="page">
  <div class="img-banner">
    <img src="../../../assets/australia-169.webp">
  </div>

  <!-- flag country - destination final - dates - tag(toll,smoke,pet)-->
  <div class="first-info-trip">

    <div class="title-flag-location">
      <h1>{{advertToDisplay.title}}</h1>
      <h1>-</h1>
      <div class="flag-location">
        <!--<span
        class="fi fi-{{advertToDisplay.itinerary[advertToDisplay.itinerary.length-1].country.isoCode | lowercase}} flag"></span>-->
        <span class="fi fi-au flag"></span>
        <h1>Sydney</h1>
      </div>
    </div>

    <div *ngIf="advertToDisplay.dateTrip.start" class="dates-trip">
      <fa-icon [icon]="faCalendarDays" />
      <div>{{advertToDisplay.dateTrip.start}}</div>
      <fa-icon [icon]="faArrowRight" />
      <div *ngIf="advertToDisplay.dateTrip.end" class="">{{advertToDisplay.dateTrip.end}}</div>
      <div *ngIf="!advertToDisplay.dateTrip.end">?</div>
    </div>
    <div class="other-tags">
      <div class="tag number-trippers">
        <span i18n>Nombre de voyageurs</span> <fa-icon [icon]="faUserGroup" />
        <span>{{advertToDisplay.numberVehicles}}</span>
      </div>
      <div class="tag toll">
        <span i18n>Péage</span> <fa-icon [icon]="faRoadBarrier" />
        <fa-icon *ngIf="advertToDisplay.toll" [icon]="faCheck" /> <!-- mettre vert ?-->
        <fa-icon *ngIf="!advertToDisplay.toll" [icon]="faXmark" /> <!-- mettre rouge ?-->
      </div>
      <div class="tag smoker">
        <span i18n> Fumeurs admis </span> <fa-icon [icon]="faSmoking" />
        <fa-icon *ngIf="advertToDisplay.smoker" [icon]="faCheck" /> <!-- mettre vert ?-->
        <fa-icon *ngIf="!advertToDisplay.smoker" [icon]="faXmark" /> <!-- mettre rouge ?-->
      </div>
      <div class="tag pet">
        <span i18n> Animaux admis </span> <fa-icon [icon]="faPaw" />
        <fa-icon *ngIf="advertToDisplay.pets" [icon]="faCheck" /> <!-- mettre vert ?-->
        <fa-icon *ngIf="!advertToDisplay.pets" [icon]="faXmark" /> <!-- mettre rouge ?-->
      </div>
    </div>
  </div>
  <!-- les escales déjà renseignées  ou pas -->
  <!-- description - informations des voyageurs (ceux dejà accepté , ...)  -->
  <div class="content">
    <div class="description-organism">
      <h3 class="title-part">Description du voyage</h3>
      <span class="description">{{advertToDisplay.description}} </span>
      <button class="go-to-trip" i18n>Participer à ce voyage</button>
    </div>
    <div class="users-info">
      <h3 class="title-part">Informations compagnons</h3>
      <div class="users" *ngFor="let tripper of trippers; let first = first">
        <div *ngIf="tripper" class="user" [class.host-user]="first">
          <div class="first-elements-user">
            <img src={{tripper.picture}} alt="img-user" class="user-img">
            <span>{{tripper.name}}</span>
            <fa-icon *ngIf="first" [icon]="faCrown" class="host-badge" />
          </div>
          <span>{{tripper.description}}</span>
        </div>
      </div>
    </div>
  </div>
</div>