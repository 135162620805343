import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnChanges {

  loginForm = new FormGroup({
    email: new FormControl(Validators.required, Validators.email),
    password: new FormControl(Validators.required),
  })

  constructor(private router: Router,) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.loginForm.valueChanges.subscribe(val => console.log(val));
    console.log(this.loginForm.valueChanges.subscribe());

  }

  ngOnInit(): void {
    this.loginForm.valueChanges;
  }


  Connection() {
    console.log("connection");

    if (this.loginForm.valid) {
      this.router.navigateByUrl('/Dashboard');
    }
  }

}
