<div class="adverts-finder">
    <div class="search-section">
        <p>Où souhaitez-vous aller ?</p>
        <app-search-bar (searchBarParams)="setSearchBarData($event)"></app-search-bar>
        <pre class="results">Il y a <span class="advert-number">{{advertsDisplayed.length}}</span> résultats</pre>
    </div>
    <div *ngIf="advertsDisplayed.length>0" class="content-section">
        <app-filter-advert [advertToFilter]="advertsDisplayed"
            (advertsFiltered)="setFilteredAdverts($event)"></app-filter-advert>
        <div class="adverts-section">
            <div *ngFor="let advert of advertsFiltered" class="card">
                <app-advert [advertToDisplay]="advert"></app-advert>
            </div>
        </div>
    </div>
</div>