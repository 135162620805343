<div class="register-component">
    <div *ngIf="emailAlreadyExists" class="error-message" role="alert" i18n>
        Cette adresse mail est déjà utilisée
    </div>
    <div *ngIf="error" class="error-message" role="alert" i18n>
        Une erreur est survenue
    </div>
    <div *ngIf="success" role="alert" i18n>
        Votre compte a bien été créé, vous allez être redirigé vers la page de connexion
    </div>
    <form [formGroup]="registrationForm" (submit)="registration()" class="register-form">
        <div class="register-container">
            <h1>Création de compte</h1>
            <input type=text formControlName="name" class="input-register" placeholder="Nom" required>
            <input type=text formControlName="email" class="input-register" placeholder="Adresse mail" required>
            <input type="password" formControlName="password" class="input-register" placeholder="Mot de passe"
                required>
            <input type="password" formControlName="confirmPassword" class="input-register"
                placeholder="Confirmation de mot de passe"  i18n required>
            <select class="input-register" formControlName="languages" multiple>
                <option value="Langues" disabled selected i18n>Langues</option>
                <option value="Français" i18n>Français</option>
                <option value="Anglais" i18n>Anglais</option>
                <option value="Espagnol" i18n>Espagnol</option>
                <option value="Allemand" i18n>Allemand</option>
                <option value="Italien" i18n>Italien</option>
                <option value="Flamand" i18n>Flamand</option>
            </select>
            <input type="button" value="valueToChangeWhenImageIsSet" hidden>
            <button type="submit" class="button-style" [disabled]="!registrationForm" i18n>Connexion</button>
        </div>
    </form>
</div>