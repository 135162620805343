import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { HomepageComponent } from './public/homepage/homepage.component'
import { MenuPrivateComponent } from './private/menu-private/menu-private.component'
import { ContactComponent } from './public/contact/contact.component'
import { LegalMentionComponent } from './public/legal-mention/legal-mention.component'
import { LoginComponent } from './public/login/login.component'
import { MenuPublicComponent } from './public/menu-public/menu-public.component'
import { RegistrationComponent } from './public/registration/registration.component'
import { ReactiveFormsModule } from '@angular/forms'
import { ChatComponent } from './private/chat/chat.component'
import { ChatService } from './services/chat.service'
import { NgxSliderModule } from 'ngx-slider-v2'

import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { AdvertModule } from './private/advert/advert.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AuthInterceptor } from './interceptor/auth.interceptor'
import { AdvertComponent } from "./private/advert/components/advert/advert.component";


const config: SocketIoConfig = {
	url: 'http://localhost:3000',
	options: {
		transports: ['websocket'],
	},
}

const firebaseConfig = {
	apiKey: 'AIzaSyCC1TCaw569gFF75OR3xYdxcgeHzmjzpT4',
	authDomain: 'voycamp-d2897.firebaseapp.com',
	projectId: 'voycamp-d2897',
	storageBucket: 'voycamp-d2897.appspot.com',
	messagingSenderId: '19595810163',
	appId: '1:19595810163:web:f5ded65669ebb8f1be6106',
	measurementId: 'G-TH03FJWHS6'
}

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		HeaderComponent,
		HomepageComponent,
		MenuPrivateComponent,
		MenuPublicComponent,
		ContactComponent,
		LegalMentionComponent,
		LoginComponent,
		RegistrationComponent,
		ChatComponent,
	],
	imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(config),
    NgxSliderModule,
    AdvertModule,
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    AdvertComponent
],
	providers: [ChatService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		}],
	bootstrap: [AppComponent],
})
export class AppModule { }
