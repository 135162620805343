<div class="publish-page">
    <div class="form">

        <form [formGroup]="publishAdvertForm" (submit)="PublishAdvert()" class="publish-advert-form">
            <div class="steps">
                <span>Step 1</span>
                <div formGroupName="destination" class="input-form destination">
                    <div class="title-section">
                        <span i18n>Où ?</span>

                        <!-- <mat-icon class="tooltip" matTooltip="Destination final du voyage"
                            aria-label="Destination final du voyage" aria-hidden="false" aria-label="Help Icon"
                            fontIcon="help_outline" /> -->

                    </div>
                    <div class="destination-organism">
                        <div>
                            <div class="title-molecule">
                                <span>
                                    Départ
                                </span>
                            </div>
                            <div class="destination-molecule">
                                <div class="input-container select-control country">
                                    <span i18n class="subtitle">Pays</span>
                                    <span *ngIf="indexCountrySelected >= 0" alt="Drapeau du pays" i18n-alt
                                        class="fi fi-{{countries[indexCountrySelected].isoCode.toLocaleLowerCase()}}"></span>
                                    <select name="country" (change)="OnSelectedCountryDestination($event)">
                                        <option *ngFor="let country of countries " value="{{country.name}}">
                                            {{country.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="input-container select-control region">
                                    <span i18n class="subtitle">Region</span>
                                    <select name="region" (change)="OnSelectedRegionDestination($event)">
                                        <option *ngFor="let region of regions " value="{{region.name}}">{{region.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="input-container select-control city">
                                    <span i18n class="subtitle">Ville</span>
                                    <select name="city" (change)="OnSelectedCityDestination($event)">
                                        <option *ngFor="let city of cities " value="{{city.name}}">{{city.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="title-destination">
                                <span class="title-molecule">
                                    Arrivé
                                </span>
                            </div>
                            <div class="destination-molecule">
                                <div class="input-container select-control country">
                                    <span i18n class="subtitle">Pays</span>
                                    <span *ngIf="indexCountrySelected >= 0" alt="Drapeau du pays" i18n-alt
                                        class="fi fi-{{countries[indexCountrySelected].isoCode.toLocaleLowerCase()}}"></span>
                                    <select name="country" (change)="OnSelectedCountryDestination($event)">
                                        <option *ngFor="let country of countries " value="{{country.name}}">
                                            {{country.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="input-container select-control region">
                                    <span i18n class="subtitle">Region</span>
                                    <select name="region" (change)="OnSelectedRegionDestination($event)">
                                        <option *ngFor="let region of regions " value="{{region.name}}">{{region.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="input-container select-control city">
                                    <span i18n class="subtitle">Ville</span>
                                    <select name="city" (change)="OnSelectedCityDestination($event)">
                                        <option *ngFor="let city of cities " value="{{city.name}}">{{city.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="steps">
                <span>Step 2</span>
                <div formGroupName="dateTrip" class="input-form date-trip">
                    <div class="title-section">
                        <span i18n>Quand ?</span>
                        <!-- <mat-icon class="tooltip" matTooltip="Destination final du voyage"
                            aria-label="Destination final du voyage" aria-hidden="false" aria-label="Help Icon"
                            fontIcon="help_outline" /> -->
                    </div>
                    <div>
                        <div class="input-container select-control">
                            <span i18n>Date de départ</span>
                            <input type="date" formControlName="start" (ngModelChange)="switchStepThree($event)">
                        </div>

                        <div class="input-container select-control">
                            <span i18n>Date de retour</span>
                            <input type="date" formControlName="end">
                        </div>
                    </div>

                </div>
            </div>

            <div class="steps">
                <span>Step 3</span>
                <div class="input-form">
                    <div class="title-section">
                        <span i18n>Comment ?</span>
                        <!-- <mat-icon class="tooltip" matTooltip="Destination final du voyage"
                            aria-label="Destination final du voyage" aria-hidden="false" aria-label="Help Icon"
                            fontIcon="help_outline" /> -->
                    </div>
                    <div>
                        <div class="input-container number-vehicle ">
                            <div class="is-tooltip">
                                <span i18n>Nombre de véhicules</span>
                                <span class="tooltip"
                                    matTooltip="Nombre de véhicules que vous souhaitez dans votre convoit"
                                    aria-label="Nombre de véhicules que vous souhaitez dans votre convoit">
                                    <mat-icon aria-hidden="false" aria-label="Help Icon" fontIcon="help_outline" />
                                </span>
                            </div>
                            <input type="number" min="0" formControlName="numberVehicles" id="numberVehicle"
                                placeholder="1,2,...">
                        </div>
                    </div>


                    <div class="primary-tags">
                        <div class="input-container primary-tags-container">
                            <div class="primary-tag-container" id="toll-container">
                                <div class="label-primary-tag"><span i18n>Péages</span> <fa-icon
                                        [icon]="faRoadBarrier" />
                                </div>
                                <div class="tag-choice">
                                    <!-- <input type="checkbox" name="toll" id="toll-yes" [(ngModel)]="tollYesSelected"
                                (change)="OnSelectedToll('toll-yes')">
                            <label class="tag" for="toll-yes">
                                <fa-icon [icon]="faCheck" />
                            </label>
                            <input type="checkbox" name="toll" id="toll-no" [(ngModel)]="tollNoSelected"
                                (change)="OnSelectedToll('toll-no')">
                            <label class="tag" for="toll-no">
                                <fa-icon [icon]="faXmark" />
                            </label> -->
                                    <input type="radio" name="toll" id="toll-yes" [checked]="tollYesSelected"
                                        (change)="OnSelectedToll('toll-yes')">
                                    <label class="tag" for="toll-yes">
                                        <mat-icon aria-hidden="false" aria-label="authorize" fontIcon="done" />
                                    </label>
                                    <input type="radio" name="toll" id="toll-no" [checked]="tollNoSelected"
                                        (change)="OnSelectedToll('toll-no')">
                                    <label class="tag" for="toll-no">
                                        <mat-icon aria-hidden="false" aria-label="authorize" fontIcon="close" />
                                    </label>
                                </div>
                            </div>
                            <div class="primary-tag-container" id="animals-container">
                                <div class="label-primary-tag">
                                    <span i18n>Animaux</span>
                                    <mat-icon aria-hidden="false" aria-label="Pets autorize or forbidden"
                                        fontIcon="pets" />
                                </div>
                                <div class="tag-choice">
                                    <input type="radio" name="animal" id="animal-yes" [checked]="tollYesSelected"
                                        (change)="OnSelectedToll('toll-yes')">
                                    <label class="tag" for="toll-yes">
                                        <mat-icon aria-hidden="false" aria-label="authorize" fontIcon="done" />
                                    </label>
                                    <input type="radio" name="animal" id="animal-no" [checked]="tollNoSelected"
                                        (change)="OnSelectedToll('toll-no')">
                                    <label class="tag" for="toll-no">
                                        <mat-icon aria-hidden="false" aria-label="authorize" fontIcon="close" />
                                    </label>

                                </div>
                            </div>
                            <div class="primary-tag-container" id="smoker-container">
                                <div class="label-primary-tag">
                                    <span i18n>Fumeur</span>
                                    <mat-icon aria-hidden="false" aria-label="Pets autorize or forbidden"
                                        fontIcon="smoking_rooms" />
                                </div>
                                <div class="tag-choice">
                                    <input type="radio" name="smoke" id="smoke-yes" [checked]="tollYesSelected"
                                        (change)="OnSelectedToll('toll-yes')">
                                    <label class="tag" for="toll-yes">
                                        <mat-icon aria-hidden="false" aria-label="authorize" fontIcon="done" />
                                    </label>
                                    <input type="radio" name="smoke" id="smoke-no" [checked]="tollNoSelected"
                                        (change)="OnSelectedToll('toll-no')">
                                    <label class="tag" for="toll-no">
                                        <mat-icon aria-hidden="false" aria-label="authorize" fontIcon="close" />
                                    </label>

                                </div>
                            </div>
                        </div>




                        <!-- <div>
                    <span i18n>Péages</span> :
                    <select name="toll" id="toll-advert" (change)="OnSelectedToll($event)">
                        <option i18n value="Oui">Oui</option>
                        <option i18n value="Non" selected="selected">Non</option>
                    </select>
                </div> -->
                    </div>
                </div>

            </div>

            <!-- <div *ngIf="publishAdvertForm.get('title').hasError('required') && publishAdvertForm.get('title').touched">
                Le titre est obligatoire.
              </div>
              <div *ngIf="publishAdvertForm.get('title').hasError('minlength') && publishAdvertForm.get('title').touched">
                Le titre doit contenir au moins 8 caractères.
              </div>
              <div *ngIf="publishAdvertForm.get('title').hasError('maxlength') && publishAdvertForm.get('title').touched">
                Le titre ne peut pas dépasser 50 caractères.
              </div> -->
            <!-- <div>
                <span i18n>Tags</span>
                <select multiple formControlName="tags" id="tags-advert" (change)="OnSelectedTags($event)">
                    <option *ngFor="let tag of tags" value="{{tag}}">{{tag}}</option>
                </select>
            </div> -->

            <div class="steps">
                <span>Step 4</span>
                <div class="input-form">
                    <div class="input-container">
                        <span i18n>Titre</span>
                        <input type="text" formControlName="title" name="title" id="titleAdvert" placeholder="Titre">
                    </div>
                    <div class="input-container secondary-tags">
                        <span class="title">Tags</span>
                        <input type="text" name="research-tags" placeholder="Recherche un tag" />
                        <div class="tags-container">
                            <span *ngFor="let tag of tagsFilter" class="">
                                <input class="input-checkbox" type="checkbox" name="{{tag}}" id="{{tag}}"
                                    (change)="OnSelectedTags(tag)" />
                                <label class="tag" for="{{tag}}">{{tag}}</label>
                            </span>
                        </div>
                    </div>

                    <div class="input-container">
                        <span i18n>Description</span>
                        <textarea formControlName="description" id="description" placeholder="Description"
                            cols="50px"></textarea>
                    </div>
                </div>
            </div>


            <!--TODO download image / voir si une génération via un prompt d'IA avec le titre-->
            <button i18n type="submit" [disabled]="!publishAdvertForm.valid">Publier l'annonce</button>
            <!--TODO Real validator-->

        </form>
    </div>

    <div class="publish-advert-preview">
        PREVIEW -> peut être en faire un component ???

    </div>
</div>