import { Component, EventEmitter, OnInit, Output } from '@angular/core'

import {
	faSearch,
	faCalendarDays,
	faLocationDot,
	faUserGroup,
	faCity,
	faFlag,
} from '@fortawesome/free-solid-svg-icons'
import {
	CityAdvert,
	CountryAdvert,
	RegionAdvert,
	SearchBar,
} from 'src/models/advert'
import { AdvertService } from '../advert.service'
@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
	constructor(private advertService: AdvertService) {}

	faLocationDot = faLocationDot
	faCalendarDays = faCalendarDays
	faUserGroup = faUserGroup
	faSearch = faSearch
	faCity = faCity
	faFlag = faFlag

	seachBar: SearchBar = {
		//TODO en faire un form comme dans publish advert form
		destination: {
			country: {
				name: '',
				isoCode: '',
			},
		},
		date: {},
		usTrippers: 0,
	}

	regions: RegionAdvert[] = []
	countries: CountryAdvert[] = []
	cities: CityAdvert[] = []

	indexCountrySelected: number = 0
	indexRegionSelected: number = 0

  @Output() searchBarParams = new EventEmitter<SearchBar>()

  ngOnInit() {
  	this.InitSearchBar()
  }

  InitSearchBar() {
  	//Destination
  	this.countries = this.advertService.getCountries()
  	this.regions = [{ name: 'Région', countryCode: '', isoCode: '' }]
  	this.cities = [{ name: 'Ville', countryCode: '', stateCode: '' }]
  }

  OnSelectedCountryDestination(event?: any) {
  	this.seachBar.destination.country = event.target.value
  	this.indexCountrySelected = this.countries.findIndex(
  		(element) => element.name === event.target.value
  	)

  	this.regions = this.advertService.getRegionOfCountry(
  		this.countries[this.indexCountrySelected].isoCode
  	)

  	// this.searchAdvertForm
  	//   .get("destination.city")
  	//   ?.patchValue(event.target.value);
  }

  OnSelectedRegionDestination(event: any) {
  	this.seachBar.destination.region = event.target.value
  	this.indexRegionSelected = this.regions.findIndex(
  		(element: any) => element.name === event.target.value
  	)

  	this.cities = this.advertService.getCitiesOfState(
  		this.countries[this.indexCountrySelected].isoCode,
  		this.regions[this.indexRegionSelected].isoCode
  	)

  	// this.searchAdvertForm
  	//   .get("destination.region")
  	//   ?.patchValue(event.target.value);
  }

  OnSelectedCityDestination(event: any) {
  	this.seachBar.destination.city = event.target.value

  	// this.publishAdvertForm
  	//   .get("destination.city")
  	//   ?.patchValue(event.target.value);
  }

  submitSearchBar() {
  	console.log(this.seachBar)
  	this.advertService.getAdvertFiltered(this.seachBar)
  	this.searchBarParams.emit(this.seachBar)
  }
}
