import { AdvertDisplayed } from "src/models/advert";

export const maxVehicles: number = 10
export const minVehicles: number = 0

export const emptyAdvertDisplayed: AdvertDisplayed = {
  id: '',
  title: '',
  description: '',
  numberVehicles: 0,
  itinerary: [{
    country: {
      name: '',
      phonecode: '',
      isoCode: '',
      flag: '',
      currency: '',
      latitude: '',
      longitude: '',
      timezones: []
    },
    region: {
      isoCode: '',
      name: '',
      countryCode: '',
      latitude: null,
      longitude: null
    },
    city: {
      name: '',
      countryCode: '',
      stateCode: '',
      latitude: null,
      longitude: null
    }
  }, {
    country: {
      name: '',
      phonecode: '',
      isoCode: '',
      flag: '',
      currency: '',
      latitude: '',
      longitude: '',
      timezones: []
    },
    region: {
      isoCode: '',
      name: '',
      countryCode: '',
      latitude: null,
      longitude: null
    },
    city: {
      name: '',
      countryCode: '',
      stateCode: '',
      latitude: null,
      longitude: null
    }
  }],
  user: {
    id: '',
    name: '',
    picture: '',
    languages: [],
    description: ''
  },
  tags: [],
  toll: false,
  smoker: false,
  pets: false,
  dateTrip: {
    start: '',
    end: ''
  },
  createdAt: '',
  updatedAt: undefined
};

export const typeAdvertDisplayed: AdvertDisplayed = {
  id: '',
  title: 'Au pays des kangourous',
  description: `Ce que j’ai en tête, c’est de débuter l’aventure à Uluru – Ayers Rock, ce site emblématique au cœur du désert australien, où l’immensité de la nature et les couleurs changeantes du rocher sacré promettent un spectacle inoubliable.
  Ensuite, direction Cairns, où nous pourrons explorer la Grande Barrière de Corail, un véritable trésor naturel et l’un des écosystèmes les plus riches au monde. Entre plongées et excursions en bateau, ce sera l’occasion de découvrir des paysages marins à couper le souffle. 
  À partir de Cairns, nous suivrons la célèbre côte Est, également appelée la "Gold Coast", avec ses plages de sable doré, ses eaux cristallines et ses petites villes côtières pleines de charme. Ce trajet mythique est ponctué de spots incontournables, comme les Whitsundays, Fraser Island ou encore Byron Bay. 
  Évidemment, on prendra le temps de chiller à Cairns après tout ce chemin pour se détendre, profiter de la ville et recharger les batteries avant de continuer l’aventure. J’ai vraiment hâte de partager ce voyage avec vous, de vivre ces moments uniques et de créer ensemble des souvenirs inoubliables ! 😊`,
  numberVehicles: 6,
  itinerary: [{
    country: {
      name: 'Australia',
      phonecode: '+61',
      isoCode: '',
      flag: 'au',
      currency: '$AU',
      latitude: '',
      longitude: '',
      timezones: []
    },
    region: {
      isoCode: '',
      name: 'Queensland',
      countryCode: '',
      latitude: null,
      longitude: null
    },
    city: {
      name: 'Cairns',
      countryCode: '',
      stateCode: '',
      latitude: null,
      longitude: null
    }
  }, {
    country: {
      name: 'Australia',
      phonecode: '+61',
      isoCode: '',
      flag: 'au',
      currency: '$AU',
      latitude: '',
      longitude: '',
      timezones: []
    },
    region: {
      isoCode: '',
      name: 'North Territory',
      countryCode: '',
      latitude: null,
      longitude: null
    },
    city: {
      name: 'Yulara',
      countryCode: '',
      stateCode: '',
      latitude: null,
      longitude: null
    }
  },
  {
    country: {
      name: 'Australia',
      phonecode: '+61',
      isoCode: '',
      flag: 'au',
      currency: '$AU',
      latitude: '',
      longitude: '',
      timezones: []
    },
    region: {
      isoCode: '',
      name: 'Queensland',
      countryCode: '',
      latitude: null,
      longitude: null
    },
    city: {
      name: 'Brisbane',
      countryCode: '',
      stateCode: '',
      latitude: null,
      longitude: null
    }
  }],
  user: {
    id: '',
    name: 'StouStou',
    picture: '',
    languages: ['Français', "English"],
    description: `Un dev qui essaye de s'expatrier ;) sans grande réussite`
  },
  tags: [],
  toll: false,
  smoker: false,
  pets: true,
  dateTrip: {
    start: '10/01/2025',
    end: '18/04/2026'
  },
  createdAt: '',
  updatedAt: undefined
};
