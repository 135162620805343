import { inject, Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog';
import axios from 'axios'
import { environment } from 'src/environments/environment'
import { LoginDTO, RegistrationDTO } from 'src/models/user'
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';

@Injectable({
	providedIn: 'root'
})
export class PublicService {
	readonly dialog = inject(MatDialog);


	constructor() { }

	async registration(registerUserData: RegistrationDTO) {
		try {
			const response = await axios.post(`${environment.apiURL}/user/registration`, registerUserData)
			console.log('publicService - regisration : ', response)
			return response.status
		} catch (error: any) {
			console.error('publicService - regisration : ', error)
			return error.response.status
			// return error.response.status
		}
	}


	async login(loginUserData: LoginDTO) {
		try {
			const response = await axios.post(`${environment.apiURL}/auth/login`, loginUserData)
			return response
		}
		catch (error: any) {
			return error.response
		}
	}

	private openDialog(component: any, heightDialog: string, widthDialog: string) {
		this.dialog.open(component, { height: heightDialog, width: widthDialog, closeOnNavigation: true, autoFocus: true }); //TODO responsive
	}


	public openRegisterDialog(){
		this.openDialog(RegistrationComponent, '684px', '640px' )
	}

	public openLogInDialog(){
    this.openDialog(LoginComponent, "500px", "640px" )
  }
}